<template>
  <page-container ref="pageContainer" :scroll-top.sync="scrollTop">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div
          v-for="(item, index) in dataList"
          :key="index"
          class="shoppingCart"
        >
          <div>
            <!-- <van-checkbox-group v-model="selectId" ref="checkboxGroup">
              <van-checkbox :name="item.id">111</van-checkbox>
            </van-checkbox-group> -->
          </div>
          <van-swipe-cell :before-close="beforeClose" :name="item.id">
            <div class="cardBox">
              <div>
                <van-checkbox-group v-model="selectId" ref="checkboxGroup">
                  <van-checkbox
                    :name="item.id"
                    :disabled="item.goodsStatus !== 0"
                  >
                  </van-checkbox>
                </van-checkbox-group>
              </div>
              <van-card
                :price="item.goodsPrice.toFixed(2)"
                :desc="item.specTitle"
                :title="item.name"
                class="goods-card"
                @click="handleGoodsClick(item.goodsStatus, item.goodsId)"
              >
                <!--  -->
                <template #thumb>
                  <div class="felx">
                    <van-image
                      width="80px"
                      height="80px"
                      :src="item.imageUrl"
                      style="margin-left: 10px"
                    />
                    <div :class="item.goodsStatus !== 0 ? 'mask' : ''">
                      {{
                        item.goodsStatus === 1
                          ? "下架"
                          : item.goodsStatus === 2
                          ? "无货"
                          : item.goodsStatus === 3
                          ? "无此规格"
                          : ""
                      }}
                    </div>
                  </div>
                </template>
                <template #num> </template>
              </van-card>
              <div class="stepperSty">
                <van-stepper
                  v-model="item.count"
                  @change="conutOnChange(item.id, item.count)"
                  :disabled="item.goodsStatus !== 0"
                />
              </div>
            </div>
            <template #right>
              <van-button
                square
                text="删除"
                type="danger"
                class="delete-button"
              />
            </template>
          </van-swipe-cell>
        </div>
      </van-list>
    </van-pull-refresh>
    <div class="submitBar">
      <div>
        <van-checkbox v-model="checkedAll" @click="checkAll">全选</van-checkbox>
      </div>
      <div class="submitBar-right">
        <div>
          <div>
            合计: <span style="color: red; font-size: 14px">¥</span>
            <span style="color: red; font-size: 18px">{{ allMoneyCom }}</span>
          </div>
        </div>
        <div @click="handleToByBtnClick"><van-button type="danger" round :disabled="this.selectList.length==0">结算</van-button></div>
      </div>
    </div>
  </page-container>
</template>

<script>
import {
  getGoodsCart,
  deleteGoodsCart,
  editGoodsCart,
} from "@/api/shoppingCart";
import { SwipeCell, Card, Toast } from "vant";
import { mapState } from 'vuex';

export default {
  name: "shoppingCart",
  components: {
    [SwipeCell.name]: SwipeCell,
    [Card.name]: Card,
  },
  data() {
    return {
      scrollTop: 0,
      checkedAll: false,
      dataList: [],
      selectId: [],
      selectList:[],
      loading: false,
      finished: false,
      refreshing: false,
      allMoney: 0,
      pagination: {
        // 分页参数
        pageNum: 0,
        pageSize: 10,
        total: 0,
      },
      goodsStatusCount: 0,
    };
  },
  computed: {
    allMoneyCom() {
      this.allMoney = 0;
      this.selectList=[]
      this.selectId.forEach((item) => {
        this.dataList.forEach((ele) => {
          if (item === ele.id){
             this.allMoney += ele.goodsPrice * ele.count
             this.selectList.push(ele)
          };
        });
      });
      return this.allMoney.toFixed(2);
    },

  },
  methods: {
    onLoad() {
      this.pagination.pageNum++;
      let {
        pagination: { pageNum: current, pageSize: size },
      } = this;
      getGoodsCart({
        current,
        size,
      }).then((res) => {
        this.goodsStatusCount = 0;
        this.dataPageLoadingSuccess();
        let { records: dataList = [], total = 0 } = res || {};
        this.dataList = [...this.dataList, ...dataList];
        this.pagination.total = total || 0;
        this.dataList.forEach((item) => {
          if (item.goodsStatus !== 0) {
            this.goodsStatusCount++;
          }
        });
        // 加载状态结束
        this.loading = false;
        if (this.dataList.length >= this.pagination.total || !dataList.length)
          this.finished = true;
      });
    },
    //  getSum(){
    //             // 函数计算中的状态改变后， 函数会自动执行一遍
    //             var sum = 0;
    //             for(var i in this.selectId){
    //             sum+= this.selectId[i].count*this.selectId[i].goodsPrice;
    //             }
    //             return this.allMoney=sum;
    //         },
    // position 为关闭时点击的位置
    // instance 为对应的 SwipeCell 实例
    beforeClose({ name, position, instance }) {
      switch (position) {
        case "left":
        case "cell":
        case "outside":
          instance.close();
          break;
        case "right":
          deleteGoodsCart(name).then((res) => {
            if (res) {
              Toast("删除成功");
              this.onRefresh();
            }
          });
          // Dialog.confirm({
          //   message: '确定删除吗？',
          // }).then(() => {
          //   instance.close();
          // });
          break;
      }
    },
    change(item) {
      console.log(item);
      // this.getSum()
    },
    changeBtn() {

    },

    // 全选
    checkAll() {
      // this.$refs.checkboxGroup.toggleAll(true);
      this.checkedAll = true;
      this.dataList.forEach((item) => {
        if (item.goodsStatus === 0) {
          if (!this.selectId.includes(item.id)) {
            this.selectId.push(item.id); // 否则将未选中的全部加入已选列表中
          }
        }
      });
    },
    conutOnChange(id, count) {

      editGoodsCart({ id, count }).then((res) => {});
    },
    dataPageLoadingSuccess() {
      this.loading = false;
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.pagination.pageNum = 0;
      this.dataList = [];
      this.selectId = [];

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    handleGoodsClick(goodsStatus, goodsId) {
      // if(goodsStatus!==0) return
      this.$router.push({
        path: "/goodsDetail",
        query: {
          normalGoodsId: goodsId,
        },
      });
    },
    handleToByBtnClick() {

      let goodsIds=[]
      this.selectList.forEach(item=>{
        goodsIds.push(item.goodsId)
        item.tagIcon=item.imageUrl

      })
      this.$router.push({
        path: '/shoppingCartPayMent',
        query: {
          cartIds:this.selectId.join(','),
          selectList:JSON.stringify(this.selectList),
          goodsIds:JSON.stringify(goodsIds)
        },
      });
    },
    onSubmit() {},
  },
  watch: {
    selectId(val) {
      const dataListLength =this.dataList.length - this.goodsStatusCount
     
      if (
        this.dataList.length !== 0 &&
        val.length === dataListLength
      ) {
        this.checkedAll = true;
      } else {
        this.checkedAll = false;
      }
    },
    deep: true,
    immediate: true,
        '$route.path'(toPath, fromPath){
            // console.log('当前页面路由地址：' + toPath)
            if(toPath==='/home/shoppingCart'){
              this.onRefresh()

            }
         },
     deep: true,
    immediate: true,
  }
};
</script>

<style lang='less' scoped>
::v-deep .shoppingCart {
  .cardBox {
    display: flex;
    background-color: #fff;
    align-items: center;
    margin-bottom: 10px;
    padding: 16px 10px;
    position: relative;
    .stepperSty {
      position: absolute;
      bottom: 16px;
      right: 18px;
    }
  }
  .mask {
    height: 100%;
    width: 100%;
    background: #c9c3c3;
    position: absolute;
    opacity: 0.6;
    top: 0px;
    left: 0;
    text-align: center;
    padding-top: 28px;
    box-sizing: border-box;
    font-size: 18px;
    color: black;
    font-weight: 700;
  }
  .van-card {
    padding: 0 0 0 4px !important;
  }
  .goods-card {
    margin: 0;
    background-color: #fff;
    //  margin-bottom: 10px;
    width: 100%;
  }

  .delete-button {
    height: 100%;
  }
  .felx {
    display: flex;
    align-items: center;
  }
  .van-card__thumb {
    // width: 122px !important;
  }

  .van-list {
    padding-bottom: 75px !important;
  }
  .van-card__price {
    color: red;
    font-weight: 700;
  }
  .van-card__desc {
    margin-top: 8px;
  }
}
::v-deep .submitBar {
  position: fixed;
  bottom: 50px;
  left: 0;
  background-color: #fff;
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  .submitBar-right {
    display: flex;
    align-items: center;
    > div {
      margin-left: 10px;
    }
  }
  .van-button--danger {
    padding: 10px 30px !important;
  }
  .van-button {
    height: auto !important;
  }
}
::v-deep .van-checkbox__icon--checked .van-icon {
  background-color: #eb6013;
  border-color: #eb6013;
}
</style>
<template>
  <div
      ref="$pageContainer"
      class="__page-container"
      @scroll="handleOnScroll"
  >
    <slot/>
  </div>
</template>

<script>
import { throttle } from '@/util';

export default {
  name: 'PageContainer',
  props: {
    scrollTop: {
      default: () => 0,
    },
  },
  beforeMount() {
    this.handleOnScroll = throttle(this.handleOnScroll, 1000 / 30);
  },
  mounted() {
  },
  methods: {
    handleOnScroll() {
      this.$emit('update:scrollTop', this.$refs.$pageContainer.scrollTop);
    },
    scrollToTop(top) {
      this.$refs.$pageContainer.scroll(0, top);
    },
  },
};
</script>

<style lang="less" scoped>
.__page-container {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>

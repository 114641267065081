var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',{ref:"pageContainer",attrs:{"scroll-top":_vm.scrollTop},on:{"update:scrollTop":function($event){_vm.scrollTop=$event},"update:scroll-top":function($event){_vm.scrollTop=$event}}},[_c('van-pull-refresh',{staticStyle:{"position":"relative","z-index":"1"},on:{"refresh":_vm.onRefresh},model:{value:(_vm.refreshing),callback:function ($$v) {_vm.refreshing=$$v},expression:"refreshing"}},[_c('div',{staticClass:"banner-background"}),_c('van-sticky',{attrs:{"container":_vm.container},on:{"change":function (top) {this$1.topSearchIsFixed = top}}},[_c('van-search',{class:{ topSearchIsFixed: _vm.topSearchIsFixed },attrs:{"readonly":true,"shape":"round","show-action":"","placeholder":"搜索商品","clearable":true},on:{"click":_vm.handleSearchGoods},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('div',{staticClass:"system-notice",class:{ 'empty-msg': !_vm.unreadCount }},[_c('van-badge',{attrs:{"content":_vm.unreadCount}},[_c('van-icon',{attrs:{"name":"bell"},on:{"click":_vm.handleSystemNoticeClick}})],1)],1)]},proxy:true}])})],1),_c('div',{staticClass:"home-img-swiper-wrap"},[_c('div',{staticClass:"swiper-container"},[_c('van-swipe',{staticStyle:{"height":"100%"},attrs:{"autoplay":5000}},_vm._l((_vm.imageList),function(ref,index){
var imageUrl = ref.imageUrl;
var href = ref.href;
return _c('van-swipe-item',{key:index,on:{"click":function($event){return _vm.handleAdClick(href)}}},[_c('van-image',{attrs:{"src":imageUrl,"fill":"cover"}})],1)}),1)],1)]),_c('div',{staticClass:"goods-class-list-container"},_vm._l((_vm.goodsClassFormatList),function(goodsClass){return _c('div',{key:goodsClass.key,class:goodsClass.className,style:({
            background: ("url(homeImg/home-class/" + (goodsClass.bgIndex) + ".png) center no-repeat"),
            backgroundSize: '100.3% 100.3%',
          }),on:{"click":function($event){return _vm.handleClassifyGoodsList(goodsClass.id, goodsClass.routePath)}}},[_c('div',[_vm._v(_vm._s(goodsClass.name))])])}),0),_c('div',{staticClass:"home-home-goods-list-container"},[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.dataList),function(item){return _c('goods-info',{key:item.id,attrs:{"view-model":"column","value":{
            goodsImgSrc: item.thumbImageUrl,
            goodsName: item.name,
            goodsDesc: '',
            goodsPrice: item.goodsPrice,
          }},on:{"click":function($event){return _vm.handleGoodsClick(item)}}})}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
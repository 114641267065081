import request from '@/plugins/axios';

/**
 * 仓库商品列表
 */
export function getWarehouseGoodsList(params = {}) {
  return request({
    url: 'shop-mobile/warehouse/multiQuery',
    method: 'put',
    data: params
  });
}

/**
 * 申请仓库发货
 */
export function deliverWarehouseGoods(data = {}) {
  return request({
    url: 'shop-mobile/warehouseRecord/deliverGoods',
    method: 'post',
    data
  });
}

/**
 * 发货记录
 */
export function getWarehouseSendRecordsList(params = {}) {
  return request({
    url: 'shop-mobile/warehouseRecord/selectAll',
    method: 'get',
    params
  });
}

/**
 * 查询物流（根据仓库申请记录号）
 */
export function queryExpressByRecordId(params, opts) {
  return request({
    url: `shop-mobile/warehouseRecord/queryExpress/${params.id}`,
    method: 'get',
    params,
    ...opts
  });
}

/**
 * /warehouseRecord/receiving
 */
export function warehouseRecordReceiving(data) {
  return request({
    url: `shop-mobile/warehouseRecord/receiving`,
    method: 'post',
    data,
  });
}

// 仓库发货物流费查询
export function getWarehouseAreaPostage(params = {}, opts = {}) {
  return request({
    url: `shop-mobile/warehouseRecord/getAreaPostage`,
    method: 'get',
    params,
    ...opts
  });
}

// 创建仓库发货物流订单 type： (01 微信支付)  (04 余额支付)
export function deliverGoodsCarriage(data = {}, opts = {}) {
  return request({
    url: `shop-mobile/warehouseRecord/deliverGoodsCarriage`,
    method: 'post',
    data,
    ...opts
  });
}

// 表单序列化
export const serialize = data => {
  let list = []
  Object.keys(data).forEach(ele => {
    list.push(`${ele}=${data[ele]}`)
  })
  return list.join('&')
}

// 获取url中参数对象
export function getLocationSearch(locationSearch) {
  let p = {};
  locationSearch = locationSearch || window.location.search;
  let search = locationSearch.split('?')[1] || '';
  search.split('&').forEach(e => {
    let [k, v] = e.split('=');
    p[k] = v;
  });
  return p;
}

// 安全获取对象的值
export const objectGetter = (obj = {}, keys = '', separator = '.') => {
  let keyArr = keys.split(separator);
  let val = JSON.parse(JSON.stringify(obj));
  for (let i = 0; i < keyArr.length; i++)
    if (typeof val === 'object' && val)
      val = val[keyArr[i]];
    else if (i < keyArr.length)
      return;
  return val;
};

export const throttle = (fn, delay, deBounce) => {
  delay = delay || 1000 / 60;
  let now, lastExec, timer, context, args;
  let execute = function () {
    fn.apply(context, args || []);
    lastExec = now;
  };
  return function () {
    context = this;
    if (deBounce) {
      clearTimeout(timer);
      timer = setTimeout(execute, delay);
      return;
    }
    args = Array.prototype.slice.apply(arguments);
    now = new Date().getTime();
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    if (lastExec) {
      let diff = delay - (now - lastExec);
      if (diff < 0)
        execute();
      else
        timer = setTimeout(execute, diff);
    } else
      execute();
  };
};


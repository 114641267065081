import Vue from 'vue';
import SignContractVue from './signContract.vue';

let SignContractConstructor = Vue.extend(SignContractVue);
let instance = null;

const SignContract = opts => {
  opts = opts || '';

  instance = new SignContractConstructor({
    data() {
      return { opts };
    }
  });
  instance.$mount();

  return instance;
};

let install = opts => {
  if (!instance)
    SignContract(opts);

  return instance.signContract(opts);
};

export default install;

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-nav-bar',{attrs:{"title":_vm.$route.meta.name,"left-arrow":""},on:{"click-left":function($event){return _vm.$router.back()}}}),_c('div',{staticClass:"warehouse-goods-container nav-page-container"},[_c('page-container',{ref:"pageContainer",staticClass:"warehouse-goods-page-container",attrs:{"scroll-top":_vm.scrollTop},on:{"update:scrollTop":function($event){_vm.scrollTop=$event},"update:scroll-top":function($event){_vm.scrollTop=$event}}},[_c('van-pull-refresh',{on:{"refresh":_vm.onRefresh},model:{value:(_vm.refreshing),callback:function ($$v) {_vm.refreshing=$$v},expression:"refreshing"}},[_c('van-list',{attrs:{"finished":_vm.finished},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_vm._l((_vm.goodsList),function(item){return _c('div',{key:item.id,staticClass:"warehouse-goods-item",on:{"click":function($event){return _vm.sendWarehouseGoods(item)}}},[_c('goods-info',{attrs:{"view-model":"small","value":{
                goodsImgSrc: item.imageUrl,
                goodsName: item.goodsName,
                goodsDesc: '×' + item.goodsCountNow,
              }}}),_c('div',{staticClass:"send-btn"},[_vm._v(" 发货 "),_c('van-icon',{attrs:{"name":"arrow"}})],1)],1)}),(!_vm.dataList.length && !_vm.loading)?_c('van-empty',{attrs:{"description":"您还没有相关的订单"}},[_c('van-button',{staticClass:"bottom-button",attrs:{"round":"","type":"primary"},on:{"click":function () {
            _vm.$store.commit('normalGoodsDetail/setRefreshing', true)
            _vm.$router.push('/normalGoodsList')
          }}},[_vm._v(" 随便逛逛 ")])],1):_vm._e()],2)],1)],1),_c('div',{staticClass:"send-history-btn",on:{"click":function($event){return _vm.$router.push('/warehouseSendRecords')}}},[_c('p',[_vm._v("查看发货记录"),_c('van-icon',{attrs:{"name":"arrow"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div
      class="goods-info-item"
      :class="{
        'column-model': viewModel === 'column',
        'small-model': viewModel === 'small',
      }"
      @click="() => $emit('click')"
  >
    <div class="goods-info-container">
      <div class="goods-image">
        <van-image
            width="100%"
            height="100%"
            :src="goodsInfo.goodsImgSrc"
            fit="cover"
        />
      </div>
      <div class="goods-info-detail">
        <div class="goods-name">{{ goodsInfo.goodsName }}</div>
        <div class="goods-desc" v-html="goodsInfo.goodsDesc"></div>
        <div v-if="goodsInfo.tagTitle" style="font-size:14px">{{goodsInfo.tagTitle}}</div>
        <div class="goods-price">
          <div class="price-container">
            <slot name="price">
              <div v-if="goodsInfo.goodsPrice" class="price">{{ goodsInfo.goodsPrice }}</div>
            </slot>
          </div>
          <div class="num">
            <slot name="price-right"/>
          </div>
        </div>
      </div>
    </div>
    <slot name="default"/>
  </div>
</template>

<script>

const defaultGoodsInfo = () => ({
  goodsImgSrc: '',
  goodsName: '',
  goodsDesc: '',
  goodsPrice: '',
  tagTitle:'',
});

export default {
  name: 'GoodsInfo',
  props: {
    viewModel: {
      default: () => 'row', //row column small
    },
    value: {
      default: defaultGoodsInfo,
    },
    tagTitle:{
      type:String
    }
  },
  computed: {
    goodsInfo() {
      return {
        ...defaultGoodsInfo(),
        ...this.value,
      };
    },
  },

};
</script>

<style lang="less" scoped>
.goods-info-item {
  margin: 0 0 10px 0;

  .goods-info-container {
    display: flex;
    background-color: #fff;
  }

  @row-mod-height: 125px;
  @small-mode-height: 90px;
  @border-radius: 8px;
  .goods-image {
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    padding-top: @row-mod-height;
    width: @row-mod-height;
    flex-shrink: 0;
    border-radius: @border-radius;
    overflow: hidden;
    //transition: all .3s;

    .van-image {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
    }
  }

  .goods-info-detail {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    height: @row-mod-height;
    padding: 0 0 0 15px;

    .goods-name {
      display: -webkit-box;
      height: 40px;
      line-height: 22px;
      letter-spacing: 0;
      font-size: 18px;
      color: #333;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .goods-price {
      display: flex;
      // margin-top: 20px;
      line-height: 30px;

      .price-container {
        flex: 1;
        flex-shrink: 0;
        overflow: hidden;

        .price {
          font-size: 18px;
          font-weight: bolder;
          color: #e93b3d;
          white-space: nowrap;
          overflow-x: auto;

          &:before {
            content: '¥ ';
            font-size: 12px;
            font-weight: normal;
          }
        }
      }

      .num {
        flex-shrink: 0;
        font-size: 14px;
      }
    }

  }

  &.column-model {
    display: inline-block;
    box-sizing: border-box;
    width: 50%;
    font-size: 0;


    .goods-info-container {
      flex-direction: column;
      border-radius: @border-radius;
      overflow: hidden;

      .goods-image {
        position: relative;
        z-index: 1;
        box-sizing: border-box;
        padding-top: 100%;
        width: 100%;
        border-radius: 0;
      }

      .goods-info-detail {
        padding: 0 10px;
        height: auto;

        .goods-name {
          margin-top: 10px;
        }

        .goods-price {
          margin-top: 0;
        }
      }
    }

    &:nth-child(odd) {
      padding-right: 5px;
    }

    &:nth-child(even) {
      padding-left: 5px;
    }

  }

  &.small-model {
    .goods-image {
      padding-top: @small-mode-height;
      width: @small-mode-height;

    }

    .goods-info-detail {
      height: @small-mode-height;
      font-size: 12px;

      .goods-price {
        .price-container {
          .price {
            font-weight: normal;
          }
        }
      }

      .goods-name {
        -webkit-line-clamp: 1;
        font-size: 16px;
      }
    }
  }
}
</style>

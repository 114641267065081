import request from '@/plugins/axios';

/**
 * 商品广告
 */
export const getAdCarouselPageList = (params = {}) => {
  return request({
    url: 'shop-mobile/adCarousel/page',
    method: 'get',
    params
  });
};


/**
 * 商品分类
 */
export const getGoodsClassifyList = (params = {}) => {
  return request({
    url: 'shop-mobile/goods/goodsInfo/listClassify',
    method: 'get',
    params
  });
};

/**
 *分页查询所有商品
 */
export const getPageAllNormalGoods = (params = {}) => {
  return request({
    url: 'shop-mobile/goods/goodsInfo/pageGoods',
    method: 'get',
    params
  });
};

/**
 *推荐商品
 */
export function getGoodsRecommended(params = {}) {
  return request({
    url: 'shop-mobile/goods/goodsInfo/goodsRecommended',
    method: 'get',
    params
  });
}

/**
 * 推荐商品（2022/5/5版）
 */
export function shopSupplierRecommendList(params = {}) {
  return request({
    url: 'shop-mobile/shopSupplierRecommend/list',
    method: 'get',
    params
  });
}

/**
 *商品专区
 */
export function getGoodsZone(params = {}) {
  return request({
    url: 'shop-mobile/goods/goodsInfo/goodsZone',
    method: 'get',
    params
  });
}

/**
 *通过id查询
 *url/id?refereeId=
 */
export function getNormalGoodsInfoByIdAndRefereeId(params, opts = {}) {
  return request({
    url: `shop-mobile/goods/goodsInfo/${params.id}`,
    method: 'get',
    params,
    ...opts,
  });
}

/**
 *分页查询
 */
export function getPageGoods(params) {
  return request({
    url: 'shop-mobile/goods/goodsInfo/pageGoods',
    method: 'get',
    params
  });
}

import Vue from 'vue';
import {
  Loading,
  Button,
  Form,
  Field,
  Switch,
  Picker, DatetimePicker,
  Checkbox, CheckboxGroup,
  Tab, Tabs,
  Icon, Image,
  Cell, CellGroup,
  Grid, GridItem,
  Tabbar, TabbarItem,
  PullRefresh, List,
  Popup,
  Cascader,
  Tag,
  Swipe, SwipeItem,
  CountDown,
  Stepper,
  RadioGroup, Radio,
  Search,
  Overlay,
  Step, Steps,
  IndexBar, IndexAnchor,
  Sticky,
  Uploader, Badge,
  Empty,
  NavBar,
  NoticeBar,
  Row, Col,
} from 'vant';

Vue.component(Loading.name, Loading);
Vue.component(Button.name, Button);
Vue.component(Form.name, Form);
Vue.component(Field.name, Field);
Vue.component(Switch.name, Switch);
Vue.component(Picker.name, Picker);
Vue.component(DatetimePicker.name, DatetimePicker);
Vue.component(Checkbox.name, Checkbox);
Vue.component(CheckboxGroup.name, CheckboxGroup);
Vue.component(Tab.name, Tab);
Vue.component(Tabs.name, Tabs);
Vue.component(Icon.name, Icon);
Vue.component(Image.name, Image);
Vue.component(Cell.name, Cell);
Vue.component(CellGroup.name, CellGroup);
Vue.component(Grid.name, Grid);
Vue.component(GridItem.name, GridItem);
Vue.component(Tabbar.name, Tabbar);
Vue.component(TabbarItem.name, TabbarItem);
Vue.component(PullRefresh.name, PullRefresh);
Vue.component(List.name, List);
Vue.component(Popup.name, Popup);
Vue.component(Cascader.name, Cascader);
Vue.component(Tag.name, Tag);
Vue.component(Swipe.name, Swipe);
Vue.component(SwipeItem.name, SwipeItem);
Vue.component(CountDown.name, CountDown);
Vue.component(Stepper.name, Stepper);
Vue.component(RadioGroup.name, RadioGroup);
Vue.component(Radio.name, Radio);
Vue.component(Search.name, Search);
Vue.component(Overlay.name, Overlay);
Vue.component(Step.name, Step);
Vue.component(Steps.name, Steps);
Vue.component(IndexBar.name, IndexBar);
Vue.component(IndexAnchor.name, IndexAnchor);
Vue.component(Sticky.name, Sticky);
Vue.component(Uploader.name, Uploader);
Vue.component(Badge.name, Badge);
Vue.component(Empty.name, Empty);
Vue.component(NavBar.name, NavBar);
Vue.component(NoticeBar.name, NoticeBar);
Vue.component(Row.name, Row);
Vue.component(Col.name, Col);

<template>
  <page-container class="boot-container">
    <van-loading class="normal-loading" v-show="loading">登录中...</van-loading>
    <user-login v-if="setUserLogin" :uuid="uuid"/>
  </page-container>
</template>

<script>
import { getLocationSearch } from '@/util';
import { getTokenService, ipQuerySave } from '@/api/system';
import {
  SAVE_LOGIN_BETWEEN_ID,
  SAVE_LOGIN_REFEREE_ID,
  SAVE_TOKEN,
  SAVE_USER_ID
} from '@/util/storage';
import { bindUserByInvitationCode, bindWxUser } from '@/api/user';
import { Dialog } from 'vant';
import UserLogin from '@/views/userLogin';
import { signContract2_1, signContract6_1 } from '@/components/SignContract/index.js';
import { safeguardRights } from '@/api/normalOrder';

export default {
  name: 'Boot',
  components: { UserLogin },
  data() {
    return {
      loading: false,
      setUserLogin: false,
      uuid: '',
    };
  },
  created() {
    this.wechatLogin();
    let { refereeId, betweenId } = getLocationSearch();
    SAVE_LOGIN_REFEREE_ID(refereeId);
    SAVE_LOGIN_BETWEEN_ID(betweenId || '');
  },
  methods: {
    wechatLogin() {
      let bindUser = getLocationSearch().bindUser;
      if (bindUser) {
        let code = getLocationSearch().code;
        bindWxUser({ code }).then(res => {
          this.goNextPage();
        })

        return;
      }

      let shopCode = getLocationSearch().shopCode;
      if (shopCode) {
        getTokenService({
          grant_type: 'mobile-user',
          scope: 'server',
          code: shopCode,
        }).then(res => {
          this.loading = false;
          let { msg, access_token, user_id } = res.data || {};

          if (access_token) {
            SAVE_TOKEN(access_token);
            SAVE_USER_ID(user_id);
            ipQuerySave();

            let { invitationCode, state, orderNo } = getLocationSearch();
            let path = state ? decodeURIComponent(state) : '/home';
            let query = {};

            if (path === '/pcWxQrCodePay') {
              path += `?orderNo=${orderNo}`;
            }

            if (path === '/pcFastQrCodePay') {
              path += `?orderNo=${orderNo}`;
            }

            if (path === '/hrefToSchool') {
              query = { ...getLocationSearch() };
            }

            Dialog.confirm({
              title: `是否绑定微信？`,
              // message: '弹窗内容',
              beforeClose: (action, done) => {
                if (action === 'confirm') {
                  window.location.href = location.origin + '/shop-mobile/oauth/login?redirectUrl=' + encodeURIComponent(window.location.href.split('#')[0] + '&bindUser=1')
                } else {
                  done();

                  if (invitationCode)
                    bindUserByInvitationCode({ invitationCode }).then(res => {
                      this.goNextPage(path, query);
                    });
                  else
                    this.goNextPage(path, query);

                }
              },
            }).catch(() => {
            });
          } else {
            let msgArr = msg.split('::');
            if (msgArr[0] === 'new') {
              Object.assign(this, {
                setUserLogin: true,
                uuid: msgArr.slice(-1)[0],
              });
            } else {
              Dialog.alert({
                message: `二维码过期，登录失败`,
                confirmButtonText: '重试',
              });
            }
          }
        });
      } else {
        let code = getLocationSearch().code;
        this.loading = true;
        getTokenService({
          grant_type: 'weixin',
          scope: 'server',
          code,
        }).then(res => {
          this.loading = false;
          let { msg, access_token, user_id } = res.data || {};

          if (access_token) {
            SAVE_TOKEN(access_token);
            SAVE_USER_ID(user_id);
            ipQuerySave();

            let { invitationCode, state, orderNo } = getLocationSearch();
            let path = state ? decodeURIComponent(state) : '/home';
            let query = {};

            if (path === '/pcWxQrCodePay') {
              path += `?orderNo=${orderNo}`;
            }

            if (path === '/pcFastQrCodePay') {
              path += `?orderNo=${orderNo}`;
            }

            if (path === '/hrefToSchool') {
              query = { ...getLocationSearch() };
            }

            if (invitationCode)
              bindUserByInvitationCode({ invitationCode }).then(res => {
                this.goNextPage(path, query);
              });
            else
              this.goNextPage(path, query);
          } else {
            let msgArr = msg.split('::');
            if (msgArr[0] === 'new') {
              Object.assign(this, {
                setUserLogin: true,
                uuid: msgArr.slice(-1)[0],
              });
            } else {
              window.location.href = location.origin + '/shop-mobile/oauth/login?redirectUrl=' + encodeURIComponent(window.location.href);
            }
          }
        });
      }
    },
    async goNextPage(path = '/home', query = {}) {
      // await signContract2_1(); //合同2-1签署
      // await signContract6_1() //合同6-1签署

      this.$router.replace({
        path,
        query,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.boot-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

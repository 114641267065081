<template>
  <page-container
      ref="pageContainer"
      class="goods-page-container"
      :class="{'is-column-model': viewModel === 'column'}"
      :scroll-top.sync="scrollTop"
  >
    <!-- 固钉 -->
    <affix-item
        style="position: relative;z-index: 10"
        :target="() => $refs.pageContainer.$el"
        @change="flag => this.affixIsTop = flag"
    >
      <!-- 分类搜索 -->
      <ul
          class="goods-search-container"
          :class="{ affixIsTop }"
      >
        <li
            v-for="({ name, code, order }, index) of searchModelList"
            :class="{'search-model-checked': searchModelCheck.code === code}"
            :key="name"
            @click="handleSearchModelClick(searchModelList[index])"
        >
          <span class="search-name">{{ name }}</span>
          <span class="search-order" v-if="order !== null"><van-icon
              :name="order === true ? 'ascending' : 'descending'"
          /></span>
        </li>
        <li>
          <van-icon
              name="apps-o"
              @click="() => {
                this.viewModel = viewModel === 'row' ? 'column' : 'row'
              }"
          />
        </li>
      </ul>
    </affix-item>
    <!-- 下拉刷新 -->
    <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        style="position: relative;z-index: 1"
    >
      <!-- 更多列表加载 -->
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <goods-info
            v-for="item in dataList"
            :view-model="viewModel"
            :key="item.id"
            @click="handleGoodsClick(item)"
            :value="{
              goodsImgSrc: item.thumbImageUrl,
              goodsName: item.name,
              goodsDesc: '',
              goodsPrice: item.goodsPrice,
            }"
        >
          <!--                    <template #price-right>-->
          <!--                      ×123-->
          <!--                    </template>-->
        </goods-info>
      </van-list>
    </van-pull-refresh>
  </page-container>
</template>

<script>
import { getPageAllNormalGoods } from '@/api/normalGoods';
import { mapState } from 'vuex';
import GoodsInfo from '@/components/GoodsInfo';
import AffixItem from '@/components/Affix';

export default {
  name: 'goods',
  components: { AffixItem, GoodsInfo },
  data() {
    return {
      viewModel: 'row', //column
      affixIsTop: false,

      // asc desc
      searchModelList: [
        { name: '综合', code: '综合', order: null },
        { name: '销量', code: '销量', order: true },
        { name: '价格', code: '价格', order: false },
      ],
      searchModelCheck: {},

      scrollTop: 0,
      refreshing: false,
      loading: false,
      finished: false,
      dataList: [],
      pagination: {
        // 分页参数
        pageNum: 0,
        pageSize: 10,
        total: 0,
      },
    };
  },
  computed: {
    ...mapState({
      storeRefreshing: state => state.homeGoods.refreshing,
    }),
    searchData() {
      let { searchModelCheck, searchModelList } = this;
      let [def] = searchModelList;
      let { code: defCode, order: defOrder } = def;
      let { code = defCode, order = defOrder } = searchModelCheck;
      let orderKey = {};
      if (order !== null) {
        orderKey = {
          orderKey: order ? 'asc' : 'desc'
        };
      }
      return { code, ...orderKey };
    },
  },
  created() {
    this.searchModelCheck = this.searchModelList[0];
  },
  mounted() {
    this.$watch('storeRefreshing', () => {
      if (!this.storeRefreshing)
        return;
      this.$store.commit('homeGoods/setRefreshing', false);
      this.onRefresh();
    }, { immediate: true });
  },
  activated() {
    this.$refs.pageContainer.scrollToTop(this.scrollTop);
  },
  methods: {
    onLoad() {
      this.pagination.pageNum++;
      let {
        searchData,
        pagination: { pageNum: current, pageSize: size },
      } = this;

      return getPageAllNormalGoods({
        classifyId: this.$route.query.classifyId || '',
        ...searchData,
        current,
        size,
      }).then(data => {
        this.dataPageLoadingSuccess();
        let { records: dataList = [], total = 0 } = data || {};
        this.dataList = [...this.dataList, ...dataList];
        this.pagination.total = total || 0;
        if (this.dataList.length >= this.pagination.total || !dataList.length)
          this.finished = true;
      });
    },
    onRefresh() {
      // 清空列表数据
      this.dataList = [];
      this.pagination.pageNum = 0;
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      return this.onLoad();
    },
    dataPageLoadingSuccess() {
      this.loading = false;
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
    },

    handleSearchModelClick(obj) {
      if (this.loading) return;
      let { name, order } = this.searchModelCheck;
      if (obj.name === name && order !== null)
        this.searchModelCheck.order = !this.searchModelCheck.order;
      else
        this.searchModelCheck = obj;

      this.onRefresh();
    },
    handleGoodsClick(obj) {
      this.$router.push({
        path: '/goodsDetail',
        query: {
          normalGoodsId: obj.id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@bg-color: #f6f6f6;

.goods-page-container {
  padding-top: 84px;
  background: url("../../img/categories/topBackground.png") top center no-repeat #fff;
  background-size: 100% auto;

  ul.goods-search-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 0 26px;
    height: 40px;
    background-color: #fff;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;

    &.affixIsTop {
      border-radius: 0;
    }

    & > li {
      display: flex;
      align-items: center;
      font-size: 16px;

      &.search-model-checked {
        color: @primary-color;
      }

      .search-name {
        line-height: 1;
      }

      .search-order {
        margin-left: 6px;
      }

    }
  }

  .van-icon.van-icon-apps-o {
    font-size: 26px;
    color: #999;
  }


  .van-pull-refresh {
    background-color: #fff;
  }

  .van-list {
    padding: 10px 10px 0 10px;
  }


  &.is-column-model {
    background-color: @bg-color;

    .van-icon.van-icon-apps-o {
      color: @primary-color;
    }

    .van-pull-refresh {
      background-color: @bg-color;
    }

    .van-list {
      padding: 10px 10px 0 10px;
    }

  }

}
</style>

export default {
  namespaced: true,

  state: {
    refreshing: false,
    expressInfo: {},
  },
  getters: {
  },
  mutations: {
    setRefreshing(state, payload) {
      state.refreshing = payload;
    },
    setExpressInfo(state, payload) {
      state.expressInfo = payload;
    },
  },
  actions: {
  },
};

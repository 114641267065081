const TOKEN_NAME = 'pany_token';
const USER_ID_NAME = 'pany_userId';
const REFEREE_ID_NAME = 'pany_login_refereeId';
const BETWEEN_ID_NAME = 'pany_betweenId';
const USER_SEARCH_HISTORY_NAME = 'pany_userSearchHistory';

export const SAVE_TOKEN = token => {
  window.sessionStorage.setItem(TOKEN_NAME, token);
};
export const GET_TOKEN = () => {
  return window.sessionStorage.getItem(TOKEN_NAME);
};

export const SAVE_USER_ID = userId => {
  window.sessionStorage.setItem(USER_ID_NAME, userId);
};
export const GET_USER_ID = () => {
  return window.sessionStorage.getItem(USER_ID_NAME);
};

export const SAVE_LOGIN_REFEREE_ID = refereeId => {
  window.sessionStorage.setItem(REFEREE_ID_NAME, refereeId || '');
};
export const GET_LOGIN_REFEREE_ID = () => {
  return window.sessionStorage.getItem(REFEREE_ID_NAME) || '';
};

export const SAVE_LOGIN_BETWEEN_ID = betweenId => {
  window.sessionStorage.setItem(BETWEEN_ID_NAME, betweenId);
};
export const GET_LOGIN_BETWEEN_ID = () => {
  return window.sessionStorage.getItem(BETWEEN_ID_NAME);
};

export const SAVE_USER_SEARCH_HISTORY = searchArr => {
  searchArr = Array.isArray(searchArr) ? searchArr : [];
  window.localStorage.setItem(USER_SEARCH_HISTORY_NAME, JSON.stringify(searchArr));
};
export const GET_USER_SEARCH_HISTORY = () => {
  let searchArr = JSON.parse(window.localStorage.getItem(USER_SEARCH_HISTORY_NAME));
  return Array.isArray(searchArr) ? searchArr : [];
};

import request from '@/plugins/axios';

/**
 * 升级店铺差额
 */
export function getUpgradeDifference(data) {
  return request({
    url: 'shop-mobile/user/howMuchToAgency',
    method: 'post',
    data
  });
}
/**
 *注册店铺
 */
export function registeredStore(data) {
  return request({
    url: 'shop-mobile/user/registerAgency',
    method: 'post',
    params: data,
  });
}
/**
 *获取店铺
 */
export function getStore(data) {
  return request({
    url: `shop-mobile/user/getAgency`,
    method: 'post',
    params: data,
  });
}

// 订单状态
// 00: '等待拼团完成'
// 01: '待付款'
// 02: '待发货'
// 03: '待收货'
// 04: '已完成'
// 05: '已关闭'
// 06: '维权申请'
// 07: '维权完成'
// 08: '订单取消'

const formatMap = arr => {
  let map = {};
  arr.forEach(e => map[e.id] = e.name);
  return map;
};

export const DENG_DAI_PIN_TUAN_WAN_CHENG = '00';
export const DAI_FU_KUAN = '01';
export const DAI_FA_HUO = '02';
export const DAI_SHOU_HUO = '03';
export const YI_WAN_CHENG = '04';
export const YI_GUAN_BI = '05';
export const WEI_QUAN_SHEN_QING = '06';
export const WEI_QUAN_WAN_CHENG = '07';
export const DING_DAN_QU_XIAO = '08';
export const CANG_KU_ZHONG = '09';
export const CANG_KU_GUAN_BI = '10';
export const ORDER_STATUS_LIST = [
  { id: DENG_DAI_PIN_TUAN_WAN_CHENG, name: '等待拼团完成' },
  { id: DAI_FU_KUAN, name: '待付款' },
  { id: DAI_FA_HUO, name: '待发货' },
  { id: DAI_SHOU_HUO, name: '待收货' },
  { id: YI_WAN_CHENG, name: '已收货' }, // 已完成
  { id: YI_GUAN_BI, name: '已完结' }, // 已关闭
  { id: WEI_QUAN_SHEN_QING, name: '维权申请' },
  { id: WEI_QUAN_WAN_CHENG, name: '维权完成' },
  { id: DING_DAN_QU_XIAO, name: '订单取消' },
  { id: CANG_KU_ZHONG, name: '仓库中' },
  { id: CANG_KU_GUAN_BI, name: '仓库中' }, //说明：这种状态下，不可退款
];

export const ORDER_STATUS = {
  DENG_DAI_PIN_TUAN_WAN_CHENG,
  DAI_FU_KUAN,
  DAI_FA_HUO,
  DAI_SHOU_HUO,
  YI_WAN_CHENG,
  YI_GUAN_BI,
  WEI_QUAN_SHEN_QING,
  WEI_QUAN_WAN_CHENG,
  DING_DAN_QU_XIAO,
  CANG_KU_ZHONG,
  CANG_KU_GUAN_BI,
};

// 可退款状态
export const APPLY_FOR_RETURN_STATUS = [
  DAI_FA_HUO,
  DAI_SHOU_HUO,
  YI_WAN_CHENG,
  CANG_KU_ZHONG,
];

export const ORDER_STATUS_MAP = formatMap(ORDER_STATUS_LIST);

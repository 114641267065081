<!-- 仓库货物列表 -->
<template>
  <div>
    <van-nav-bar :title="$route.meta.name" left-arrow @click-left="$router.back()"/>
    <div class="warehouse-goods-container nav-page-container">
      <page-container
          ref="pageContainer"
          class="warehouse-goods-page-container"
          :scroll-top.sync="scrollTop"
      >
        <!-- 下拉刷新 -->
        <van-pull-refresh
            v-model="refreshing"
            @refresh="onRefresh"
        >
          <van-list
              v-model="loading"
              :finished="finished"
          >
            <div
                v-for="item in goodsList"
                class="warehouse-goods-item"
                :key="item.id"
                @click="sendWarehouseGoods(item)"
            >
              <goods-info
                  view-model="small"
                  :value="{
                  goodsImgSrc: item.imageUrl,
                  goodsName: item.goodsName,
                  goodsDesc: '×' + item.goodsCountNow,
                }"
              />
              <div class="send-btn">
                发货
                <van-icon name="arrow"/>
              </div>
            </div>
            <van-empty
                v-if="!dataList.length && !loading"
                description="您还没有相关的订单"
            >
              <van-button round type="primary" class="bottom-button" @click="() => {
              $store.commit('normalGoodsDetail/setRefreshing', true)
              $router.push('/normalGoodsList')
            }"> 随便逛逛
              </van-button>
            </van-empty>
          </van-list>
        </van-pull-refresh>

      </page-container>
      <div class="send-history-btn" @click="$router.push('/warehouseSendRecords')">
        <p>查看发货记录<van-icon name="arrow" /></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getWarehouseGoodsList } from '@/api/warehouse';
import GoodsInfo from '@/components/GoodsInfo';

export default {
  name: 'warehouseList',
  components: { GoodsInfo },
  data() {
    return {
      scrollTop: 0,
      refreshing: false,
      loading: false,
      finished: false,
      dataList: [],
    };
  },
  computed: {
    ...mapState({
      storeRefreshing: state => state.warehouse.refreshing,
    }),
    goodsList() {
      return this.dataList.filter(e => e.status !== '03');
    },
  },
  created() {
    this.$watch('storeRefreshing', () => {
      if (!this.storeRefreshing)
        return;

      this.$store.commit('warehouse/setRefreshing', false);
      this.onRefresh();
    }, { immediate: true });
  },
  activated() {
    this.$refs.pageContainer.scrollToTop(this.scrollTop);
  },
  methods: {
    onRefresh() {
      // 清空列表数据
      this.dataList = [];
      this.loading = true;
      this.finished = this.refreshing;
      // 重新加载数据
      getWarehouseGoodsList().then(data => {
        this.loading = false;
        this.finished = true;
        this.refreshing = false;
        this.dataList = data || [];
      });
    },

    sendWarehouseGoods(goods) {
      this.$store.commit('payment/setAddressId', null);

      let { id, goodsName, goodsCountNow, imageUrl, orderId, goodsId } = goods || {};
      this.$router.push({
        path: '/sendWarehouseGoods',
        query: {
          warehouseId: id,
          goodsName,
          goodsCountNow,
          imageUrl,
          orderId,
          goodsId,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.warehouse-goods-container {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  @send-btn-width: 66px;

  .warehouse-goods-item {
    position: relative;
    margin: 10px;
    padding: 0 @send-btn-width 0 0;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;

    .send-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      width: @send-btn-width;
      height: 100%;
      line-height: 1;
      font-size: 13px;
      letter-spacing: 1px;
      color: @primary-color;
    }

    /deep/ .goods-info-item {
      margin: 0;

      .goods-info-detail {

        .goods-desc {
          margin-top: 10px;
          font-size: 16px;
          color: #666;
        }

        .goods-price {
          display: none;
        }
      }
    }
  }

  .send-history-btn {
    position: absolute;
    z-index: 2;
    right: 20px;
    bottom: calc(28px + constant(safe-area-inset-bottom));
    bottom: calc(28px + env(safe-area-inset-bottom));
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    line-height: 1;
    border-radius: 9px;
    background-color: rgba(255, 255, 255, .9);
    border: 1px solid rgba(@primary-color, .45);

    & > p {
      font-size: 14px;
      color: rgba(@primary-color, .9);
    }
  }
}
</style>

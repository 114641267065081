import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/home/index';
import Boot from '@/views/boot';
import QrCodeLogin from '@/views/qrCodeLogin';
import HomeHome from '@/views/home/home';
import HomeGoods from '@/views/home/goods';
import Warehouse from '@/views/warehouse/warehouseGoodsList';
import HomeMy from '@/views/home/my';
import HomeShoppingCart from '@/views/home/shoppingCart';
import { wxShareByStore, hideShare } from '@/util/wxShare';
import { getLocationSearch } from '@/util';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '*',
  //   redirect: '/home/home',
  // },
  {
    path: '/',
    name: 'boot',
    meta: { name: '优之康品商城' },
    component: Boot
  },
  {
    // path: '/qrCodeLogin',
    path: '/oldUserLogin',
    name: 'qrCodeLogin',
    meta: { name: '老用户登录' },
    component: QrCodeLogin
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/home/home',
    meta: { keepAlive: true, name: '优之康品商城' },
    component: Home,
    children: [
      {
        path: '/home/home',
        name: 'homeHome',
        meta: { keepAlive: true, name: '优之康品商城' },
        component: HomeHome
      },
      {
        path: '/home/Warehouse',
        name: 'homeWarehouse',
        meta: { keepAlive: true, name: '仓库' },
        component: Warehouse
      },
      {
        path: '/home/customerService',
        name: 'homeCustomerService',
        meta: { keepAlive: true, name: '呼叫客服' },
        component: () => import('@/views/customerService'),
        // component: () => import('@/views/customerService/index'),
      },


      {
        path: '/home/goods',
        name: 'homeGoods',
        meta: { keepAlive: true, name: '商品' },
        component: HomeGoods
      },

      {
        path: '/home/shoppingCart',
        name: 'homeShoppingCart',
        meta: { keepAlive: true, name: '购物车' },
        component: HomeShoppingCart
      },
      {
        path: '/home/my',
        name: 'homeMy',
        meta: { keepAlive: true, name: '我的' },
        component: HomeMy
      },
    ],
  },
  {
    path: '/normalGoodsList',
    name: 'normalGoodsList',
    meta: { keepAlive: true, name: '商品' },
    component: () => import('@/views/normalGoodsList/index'),
  },
  {
    path: '/warehouse',
    name: 'warehouse',
    meta: { keepAlive: true, name: '待提货' },
    component: Warehouse
  },
  {
    path: '/addressList',
    name: 'addressList',
    meta: { keepAlive: true, name: '收货地址' },
    component: () => import('@/views/receiverAddress/addressList'),
  },
  {
    path: '/addAddress',
    name: 'addAddress',
    meta: { name: '新建收货地址' },
    component: () => import('@/views/receiverAddress/modifyAddress'),
  },
  {
    path: '/editAddress',
    name: 'editAddress',
    meta: { name: '修改收货地址' },
    component: () => import('@/views/receiverAddress/modifyAddress'),
  },
  {
    path: '/goodsDetail',
    name: 'goodsDetail',
    meta: { name: '商品详情' },
    component: () => import('@/views/goodsDetail'),
  },
  {
    path: '/payment',
    name: 'payment',
    meta: { name: '填写订单', keepAlive: true },
    component: () => import('@/views/payment'),
  },
  {
    path: '/shoppingCartPayMent',
    name: 'shoppingCartPayMent',
    meta: { name: '填写订单', keepAlive: false },
    component: () => import('@/views/shoppingCart/shoppingCartPayMent'),
  },
  {
    path: '/shoppingCartOrderDetail',
    name: 'shoppingCartOrderDetail',
    meta: { name: '填写订单', keepAlive: false },
    component: () => import('@/views/shoppingCart/shoppingCartOrderDetail'),
  },

  {
    path: '/groupGoodsList',
    name: 'groupGoodsList',
    meta: { keepAlive: true, name: '夺标' },
    component: () => import('@/views/groupGoodsList/index'),
  },
  {
    path: '/orderList',
    name: 'orderList',
    meta: { keepAlive: true, name: '我的订单' },
    component: () => import('@/views/orderList/index'),
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    meta: { name: '订单详情' },
    component: () => import('@/views/orderDetail/index'),
  },
  {
    path: '/applyForReturn',
    name: 'applyForReturn',
    meta: { name: '退换/售后' },
    component: () => import('@/views/afterSale/applyForReturn'),
  },
  {
    path: '/afterSaleList',
    name: 'afterSaleList',
    meta: { keepAlive: true, name: '售后服务' },
    component: () => import('@/views/afterSale/afterSaleList'),
  },
  {
    path: '/sendBack',
    name: 'sendBack',
    meta: { keepAlive: true, name: '用户退货' },
    component: () => import('@/views/afterSale/sendBack'),
  },
  {
    path: '/expressList',
    name: 'expressList',
    meta: { name: '快递公司' },
    component: () => import('@/views/afterSale/expressList'),
  },
  {
    path: '/redPacket',
    name: 'redPacket',
    meta: { name: '广告红包' },
    component: () => import('@/views/my/redPacket'),
  },
  // {
  //   path: '/underUser',
  //   name: 'underUser',
  //   meta: { name: '推广' },
  //   component: () => import('@/views/my/underUser'),
  // },
  {
    path: '/promoteUsers',
    name: 'promoteUsers',
    meta: { name: '推广' },
    component: () => import('@/views/my/promoteUsers'),
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    meta: { name: '提现' },
    component: () => import('@/views/my/withdraw'),
  },
  {
    path: '/withdrawRecords',
    name: 'withdrawRecords',
    meta: { name: '提现记录' },
    component: () => import('@/views/my/withdrawRecords'),
  },
  {
    path: '/userAuthentication',
    name: 'userAuthentication',
    meta: { name: '实名认证' },
    component: () => import('@/views/my/userAuthentication'),
  },
  {
    path: '/userInfoView',
    name: 'userInfoView',
    meta: { name: '个人信息' },
    component: () => import('@/views/my/userInfoView'),
  },
  {
    path: '/userInfo',
    name: 'userInfo',
    meta: { name: '编辑个人信息' },
    component: () => import('@/views/my/userInfo'),
  },
  {
    path: '/storeInfo',
    name: 'storeInfo',
    meta: { name: '店铺信息' },
    component: () => import('@/views/my/storeInfo'),
  },
  {
    path: '/customerService',
    name: 'customerService',
    meta: { name: '系统消息' },
    component: () => import('@/views/customerService/index'),
  },
  {
    path: '/searchGoods',
    name: 'searchGoods',
    meta: { name: '搜索商品' },
    component: () => import('@/views/searchGoods/index'),
  },
  {
    path: '/sendWarehouseGoods',
    name: 'sendWarehouseGoods',
    meta: { name: '仓库发货' },
    component: () => import('@/views/warehouse/sendWarehouseGoods'),
  },
  {
    path: '/warehouseSendRecords',
    name: 'warehouseSendRecords',
    meta: { name: '仓库发货记录' },
    component: () => import('@/views/warehouse/warehouseSendRecords'),
  },
  {
    path: '/integralGoods',
    name: 'integralGoods',
    meta: { keepAlive: true, name: '积分商品' },
    component: () => import('@/views/integralGoods/index'),
  },
  {
    path: '/integralGoodsDetail',
    name: 'integralGoodsDetail',
    meta: { name: '积分商品详情' },
    component: () => import('@/views/integralGoods/integralGoodsDetail'),
  },
  {
    path: '/integralPayment',
    name: 'integralPayment',
    meta: { name: '积分支付', keepAlive: true },
    component: () => import('@/views/integralGoods/integralPayment'),
  },
  {
    path: '/integralOrderList',
    name: 'integralOrderList',
    meta: { name: '积分订单', keepAlive: true },
    component: () => import('@/views/integralGoods/integralOrderList'),
  },
  {
    path: '/integralOrderDetail',
    name: 'integralOrderDetail',
    meta: { name: '积分订单详情' },
    component: () => import('@/views/integralGoods/integralOrderDetail'),
  },
  // 积分售后
  {
    path: '/integralApplyForReturn',
    name: 'integralApplyForReturn',
    meta: { name: '退换/售后' },
    component: () => import('@/views/integralGoods/integralAfterSale/applyForReturn'),
  },
  // 积分售后列表
  {
    path: '/integralAfterSaleList',
    name: 'integralAfterSaleList',
    meta: { name: '积分售后' },
    component: () => import('@/views/integralGoods/integralAfterSale/afterSaleList'),
  },
  // 积分商品用户退货
  {
    path: '/integralSendBack',
    name: 'integralSendBack',
    meta: { keepAlive: true, name: '用户退货' },
    component: () => import('@/views/integralGoods/integralAfterSale/sendBack'),
  },
  {
    path: '/afterSale',
    name: 'afterSale',
    meta: { name: '售后' },
    // component: () => import('@/views/customerService')
    component: () => import('@/views/customerService/index'),
  },
  {
    path: '/pcWxQrCodePay',
    name: 'pcWxQrCodePay',
    meta: { name: '微信支付' },
    component: () => import('@/views/pcWxQrCodePay')
  },
  {
    path: '/pcFastQrCodePay',
    name: 'pcFastQrCodePay',
    meta: { name: '快捷支付' },
    component: () => import('@/views/pcFastQrCodePay')
  },
  {
    path: '/userNamePswLogin',
    name: 'userNamePswLogin',
    meta: { name: '老用户登录' },
    component: () => import('@/views/userNamePswLogin')
  },
  {
    path: '/bindMiniUser',
    name: 'bindMiniUser',
    meta: { name: '公众号用户绑定至小程序' },
    component: () => import('@/views/bindMiniUser')
  },
  {
    path: '/about',
    name: 'about',
    meta: { keepAlive: true, name: '关于我们' },
    component: () => import('@/views/my/about')
  },
  {
    path: '/hrefToSchool',
    name: 'hrefToSchool',
    meta: { name: ' ' },
    component: () => import('@/views/hrefToSchool')
  },
  {
    path: '/integralFlow',
    name: 'integralFlow',
    meta: { name: '积分流水' },
    component: () => import('@/views/integralFlow')
  },
  {
    path: '/user',
    name: 'user',
    meta: { name: '个人信息' },
    component: () => import('@/views/my/user'),
  },
  {
    path: '/bankList',
    name: 'bankList',
    meta: { name: '银行卡管理' },
    component: () => import('@/views/my/bankList')
  },
  {
    path: '/quickPayMent',
    name: 'quickPayMent',
    meta: { name: '快捷支付' },
    component: () => import('@/views/my/quickPayMent')
  },
  {
    path: '/addQuickPay',
    name: 'addQuickPay',
    meta: { name: '快捷支付管理' },
    component: () => import('@/views/my/addQuickPay'),
  },
  {
    path: '/addAndEditBank',
    name: 'addAndEditBank',
    meta: { name: '银行卡管理' },
    component: () => import('@/views/my/addAndEditBank'),
  },

  {
    path: '/shareLink',
    name: 'shareLink',
    meta: { name: '分享图片' },
    component: () => import('@/views/my/shareLink')
  },
  {
    path: '/depositOrderList',
    name: 'depositOrderList',
    meta: { name: '充值订单' },
    component: () => import('@/views/depositOrder/depositOrderList')
  },
  {
    path: '/agreementList',
    name: 'agreementList',
    meta: { name: '我的协议' },
    component: () => import('@/views/home/agreementList')
  },
  {
    path: '/agreement',
    name: 'agreement',
    meta: { name: '协议内容' },
    component: () => import('@/views/home/agreement')
  },
  {

    path: '/billingPage',
    name: 'billingPage',
    meta: { name: '佣金' },
    component: () => import('@/views/my/billingPage'),

  },
  {
    path: '/billing',
    name: 'billing',
    meta: { name: '佣金' },
    component: () => import('@/views/my/billing'),
  },
  {
    path: '/billingAndContribution',
    name: 'billingAndContribution',
    meta: { name: '佣金与贡献值' },
    component: () => import('@/views/my/billingAndContribution.vue')
  },

];

const router = new VueRouter({
  routes
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};

let shareGoodsDetail = false;
let shareStore = false;
router.beforeEach(async (to, from, next) => {
  if (to.path !== '/boot' && to.path !== '/' && to.path !== '/qrCodeLogin' && to.path !== '/oldUserLogin') {
    if (to.path === '/goodsDetail') {
      shareGoodsDetail = true;
    } else {
      if (shareGoodsDetail || !shareStore) {
        // wxShareByStore();
        shareGoodsDetail = false;
        shareStore = true;
      }
    }
  }

  document.title = to.meta.name;
  next();
});

let hidedSharePageNameArr = [];
router.afterEach((to, from) => {
  const href = location.href;
  if (!hidedSharePageNameArr.includes(href)) {
    hideShare();
    hidedSharePageNameArr.push(href);
  }
});

export default router;

import {
  getContractByNumberCode,
  signContractById,
  getContract,
  signContract,
  signContractByPath
} from '@/components/SignContract/api';
import $signContract from '@/components/SignContract/SignContract';

// 签约方式1
export function signContractByIdAndContractNum(contract) {
  return new Promise(resolve => {
    $signContract({
      contract,
      confirm: ({ data, done }) => {
        // TODO: test签约接口
        let next = async () => {
          done();
          resolve(true);
        };
        let { id, contractNum } = data || {};
        signContractById({ contractRecordId: id, contractNum })
          .then(next)
          .catch(next);
      },
      cancel: async ({ id, data, done }) => {
        done();
        resolve(false);
      },
    });
  });
}

// 签约方式2
export function signContractBy_Id(contract) {
  return new Promise(resolve => {
    $signContract({
      contract,
      confirm: ({ data, done }) => {
        // TODO: test签约接口
        let next = async () => {
          done();
          resolve(true);
        };
        let { id, contractNum } = data || {};
        signContract({ contractNum: contractNum.replace('-', '_'), contractRecordId: id })
          .then(next)
          .catch(next);
      },
      cancel: async ({ id, data, done }) => {
        done();
        resolve(false);
      },
    });
  });
}

// 签约方式3
export function signContractBy_Path(contract, path, orderNo) {
  return new Promise(resolve => {
    $signContract({
      contract,
      confirm: ({ data, done }) => {
        // TODO: test签约接口
        let next = async () => {
          done();
          resolve(true);
        };
        let { id, contractNum } = data || {};
        let params = { path, contractNum, contractRecordId: id };
        if (orderNo) params.orderNo = orderNo;
        signContractByPath(params)
          .then(next)
          .catch(next);
      },
      cancel: async ({ id, data, done }) => {
        done();
        resolve(false);
      },
    });
  });
}

// 登录之后
export function signContract2_1() {
  // return new Promise(resolve => resolve()); //TODO: 2-1合同暂隐
  return new Promise(resolve => {
    getContractByNumberCode({
      id: '2_1'
    }).then(async res => {
      let contractList = Array.isArray(res) ? res : [];
      for (let cnt of contractList) {
        await signContractBy_Id(cnt);
      }
      resolve();
    }).catch(resolve);
  });
}


// 7-1  购买判断  (otherAttribute & (1 << 4)) > 0
export function signContract7_1(orderNo) {
  // return new Promise(resolve => resolve()); //TODO: 7-1合同暂隐
  return new Promise(resolve => {
    getContract({
      id: '7',
      contractNum: '7-1',
      orderNo,
    }).then(async res => {
      let contractList = Array.isArray(res) ? res : [];
      for (let cnt of contractList) {
        await signContractBy_Path(cnt, 'sign7', orderNo);
      }
      resolve();
    }).catch(resolve);
  });
}

// 7-2  购买判断  (otherAttribute & (1 << 5)) > 0
export function signContract7_2(orderNo) {
  // return new Promise(resolve => resolve()); //TODO: 7-1合同暂隐
  return new Promise(resolve => {
    getContract({
      id: '7',
      contractNum: '7-2',
      orderNo,
    }).then(async res => {
      let contractList = Array.isArray(res) ? res : [];
      for (let cnt of contractList) {
        await signContractBy_Path(cnt, 'sign7', orderNo);
      }
      resolve();
    }).catch(resolve);
  });
}

// 7-3  购买判断  (otherAttribute & (1 << 5)) > 0
export function signContract7_3(orderNo) {
  return new Promise(resolve => {
    getContract({
      id: '7',
      contractNum: '7-3',
      orderNo,
    }).then(async res => {
      let contractList = Array.isArray(res) ? res : [];
      for (let cnt of contractList) {
        await signContractBy_Path(cnt, 'sign7', orderNo);
      }
      resolve();
    }).catch(resolve);
  });
}

// 6-1 居间协议 登录后请求
export function signContract6_1() {
  // return new Promise(resolve => resolve()); //TODO: 7-1合同暂隐
  return new Promise(resolve => {
    getContract({
      id: '6_1',
    }).then(async res => {
      let contractList = Array.isArray(res) ? res : [];
      for (let cnt of contractList) {
        await signContractBy_Id(cnt);
      }
      resolve();
    }).catch(resolve);
  });
}


export default {
  namespaced: true,

  state: {
    refreshing: true,

    addressId: null,
    refreshAddressState: null,
  },
  getters: {
  },
  mutations: {
    setRefreshing(state, payload) {
      state.refreshing = payload;
    },

    setAddressId(state, payload) {
      state.refreshAddressState = new Date().getTime();
      state.addressId = payload || null;
    },
  },
  actions: {
  },
};

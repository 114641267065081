<template>
  <div class="home-container">
    <!-- 子路由 -->
    <page-container class="home-tabs-page-container">
      <keep-alive>
        <router-view/>
      </keep-alive>
    </page-container>

    <van-tabbar
        v-model="tabsActive"
        active-color="#ef5009"
        @change="activeOnChange"
    >
      <van-tabbar-item
          v-for="({ name, icon }, index) of tabsList"
          :key="name"
          :icon="icon"
          @click="setActive(index)"
      >
        {{ name }}
      </van-tabbar-item>
    </van-tabbar>


    <van-overlay :show="overDlgShow">
      <div style="display: flex;align-items: center;justify-content: center;height: 100%;position: relative;">
        <van-image src="/homeImg/boom.png" width="100%" height="100%"/>
        <van-icon name="close" color="#6bbfff" style="position: absolute;z-index: 1;top: .3em; right: .3em;font-size: 24px;" @click="overDlgShow = false"/>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { signContract2_1, signContract6_1 } from '@/components/SignContract';

export default {
  name: 'homeIndex',
  data() {
    return {
      overDlgShow: false,

      tabsActive: 0,
      tabsList: [
        {
          name: '首页',
          icon: 'wap-home-o',
          routeName: 'homeHome',
          routePath: '/home/home',
          storeModuleName: 'homeHome',
        },
        // {
        //   name: '仓库',
        //   icon: 'home-o',
        //   routeName: 'homeWarehouse',
        //   routePath: '/home/warehouse',
        //   storeModuleName: 'warehouse',
        // },
        {
          name: '客服',
          icon: 'service-o',
          routeName: 'homeCustomerService',
          routePath: '/home/customerService',
          // storeModuleName: 'homeCustomerService',
        },
        {
          name: '购物车',
          icon: 'shopping-cart-o',
          routeName: 'homeShoppingCart',
          routePath: '/home/shoppingCart',
          storeModuleName: 'homeShoppingCart',
        },

        // {s
        //   name: '商品',
        //   icon: 'home-o',
        //   routeName: 'homeGoods',
        //   routePath: '/home/goods',
        //   storeModuleName: 'homeGoods',
        // },
        {
          name: '我的',
          icon: 'manager-o',
          routeName: 'homeMy',
          routePath: '/home/my',
          storeModuleName: 'homeMy',
        },
      ],
      isChangeActive: false, // 选中后再次点击判断变量
    };
  },
  async created() {
    this.tabsList.forEach(({ routeName }, index) => {
      if (this.$route.name === routeName)
        this.tabsActive = index;
    });

    await signContract2_1(); //合同2-1签署
    await signContract6_1(); //合同6-1签署
  },

  methods: {
    activeOnChange() {
      this.isChangeActive = true;
    },
    setActive(index) {
      this.isChangeActive = !this.isChangeActive;
      console.log(this.tabsList[index]);
      let { routePath, storeModuleName } = this.tabsList[index];
      if (this.isChangeActive) {
        // 改变页面对应store刷新状态变量
        if (storeModuleName) {
          this.$store.commit(`${ storeModuleName }/setRefreshing`, true);
        }
      }
      this.$router.replace({
        path: routePath,
      });
      this.isChangeActive = false;
    },
  },
  watch: {
    '$route.path'(toPath, fromPath) {
      // console.log('当前页面路由地址：' + toPath)
      if (toPath === '/home/shoppingCart') {
        this.tabsActive = 2;

      }
    },
    deep: true,
    immediate: true,
  }
};
</script>

<style lang="less" scoped>
.home-container {
  height: 100vh;

  .home-tabs-page-container {
    bottom: @tabbar-height;
    overflow: hidden;
  }

  .van-tabbar {
    box-shadow: rgba(154, 141, 141, .6) 0 0 10px 0
  }
}
</style>

// copy by ant-design-vue
const raf = window.requestAnimationFrame;
const caf = window.cancelAnimationFrame;
export const throttleByAnimationFrame = (fn) => {
  let requestId = void 0;

  let later = function later(args) {
    return function () {
      requestId = null;
      fn.apply(undefined, [...args]);
    };
  };

  let throttled = function throttled() {
    let _len = arguments.length, args = Array(_len);
    for (let _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    if (requestId == null) {
      requestId = raf(later(args));
    }
  };

  throttled.cancel = function () {
    return caf(requestId);
  };

  return throttled;
};

export function getTargetRect(target) {
  return target !== window ? target.getBoundingClientRect() : { top: 0, bottom: window.innerHeight };
}

export function getFixedTop(placeholderReact, targetRect, offsetTop) {
  if (offsetTop !== undefined && targetRect.top > placeholderReact.top - offsetTop) {
    return offsetTop + targetRect.top + 'px';
  }
  return undefined;
}

export function getFixedBottom(placeholderReact, targetRect, offsetBottom) {
  if (offsetBottom !== undefined && targetRect.bottom < placeholderReact.bottom + offsetBottom) {
    let targetBottomOffset = window.innerHeight - targetRect.bottom;
    return offsetBottom + targetBottomOffset + 'px';
  }
  return undefined;
}

// ======================== Observer ========================
const TRIGGER_EVENTS = ['resize', 'scroll', 'touchstart', 'touchmove', 'touchend', 'pageshow', 'load'];

let observerEntities = [];

export function addObserveTarget(target, affix) {
  if (!target) return;

  let entity = observerEntities.find(function (item) {
    return item.target === target;
  });

  if (entity) {
    entity.affixList.push(affix);
  } else {
    entity = {
      target: target,
      affixList: [affix],
      eventHandlers: {}
    };
    observerEntities.push(entity);

    // Add listener
    TRIGGER_EVENTS.forEach(eventName => {
      entity.eventHandlers[eventName] = target.addEventListener(eventName, () => {
        entity.affixList.forEach(targetAffix => {
          targetAffix.lazyUpdatePosition();
        });
      });
    });
  }
}

export function removeObserveTarget(affix) {
  let observerEntity = observerEntities.find(function (oriObserverEntity) {
    let hasAffix = oriObserverEntity.affixList.some(function (item) {
      return item === affix;
    });
    if (hasAffix) {
      oriObserverEntity.affixList = oriObserverEntity.affixList.filter(function (item) {
        return item !== affix;
      });
    }
    return hasAffix;
  });

  if (observerEntity && observerEntity.affixList.length === 0) {
    observerEntities = observerEntities.filter(function (item) {
      return item !== observerEntity;
    });

    // Remove listener
    TRIGGER_EVENTS.forEach(function (eventName) {
      let handler = observerEntity.eventHandlers[eventName];
      if (handler && handler.remove) {
        handler.remove();
      }
    });
  }
}

import request from '@/plugins/axios'

/*
判断点 A1.后台登录 A2.后台进货 B1.手机登录 B2.手机支付 B3.确认收货
 */
// export function getContract(params) {
//   return request({
//     url: 'shop-mobile/contractInfo/getContractInfo',
//     method: 'get',
//     params,
//   });
// }

// // 合同签约
// export function agreeContract(params = {}) {
//   return request({
//     url: `shop-mobile/contractInfo/agreeContract/${params.contractId}`,
//     method: 'get',
//     params,
//   });
// }


// 获取合同方式1
export function getContractByNumberCode(params = {}) {
  // return new Promise(resolve => setTimeout(() => resolve([
  //   {
  //     contractTitle: '合同1：balabalabalabala',
  //     contractContent: '<span style="color: red">1</span>这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗'
  //   },
  //   {
  //     contractTitle: '合同2：balabalabalabalabalabala',
  //     contractContent: '<span style="color: blue">2</span>这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗'
  //   },
  // ]), 321));

  return request({
    url: `/shop-mobile/contractInfo/isSign${params.id}`,
    method: 'get',
    headers: {
      noErrorTip: true,
    },
    params,
  });
}

// 获取合同方式2
export function getContract(params = {}) {
  // return new Promise(resolve => setTimeout(() => resolve([
  //   {
  //     contractTitle: '合同1：balabalabalabala',
  //     contractContent: '<span style="color: red">1</span>这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗'
  //   },
  //   {
  //     contractTitle: '合同2：balabalabalabalabalabala',
  //     contractContent: '<span style="color: blue">2</span>这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗这是一个模态弹窗'
  //   },
  // ]), 321));

  return request({
    url: `/shop-mobile/contractInfo/getContract${params.id}`,
    method: 'get',
    headers: {
      noErrorTip: true,
    },
    params,
  });
}

// 签合同方式1
// 请求方式 /sign?contractRecordId=
export function signContractById(params) {
  return request({
    url: `/shop-mobile/contractInfo/sign`,
    method: 'get',
    headers: {
      noErrorTip: true,
    },
    params,
  });
}

// 签合同方式2
// 请求方式 /sign4_3
export function signContract(params) {
  return request({
    url: `/shop-mobile/contractInfo/sign${params.contractNum}`,
    method: 'get',
    headers: {
      noErrorTip: true,
    },
    params,
  });
}

// 签合同方式3 自定义path
export function signContractByPath(params) {
  return request({
    url: `/shop-mobile/contractInfo/${params.path}`,
    method: 'get',
    headers: {
      noErrorTip: true,
    },
    params,
  });
}

// 创建6-1合同
export function create6_1(params) {
  return request({
    url: `/shop-mobile/contractInfo/create6_1`,
    method: 'get',
    headers: {
      noErrorTip: true,
    },
    params,
  });
}



<template>
  <page-container class="old-user-login-container">
    <van-form
        v-if="showLogin"
        @submit="oldUserLogin"
        label-width="4.5em"
        style="margin-top: 2em"
    >
      <van-field
          v-model.trim="oldUserLoginForm.username"
          name="username"
          label="用户名"
          placeholder="用户名"
          :rules="[{ required: true, message: '请填写用户名' }]"
      />
      <van-field
          v-model.trim="oldUserLoginForm.pwd"
          name="password"
          label="密码"
          placeholder="密码"
          border
          :rules="[{ required: true, message: '请填写密码' }]"
      />

      <van-button block round size="small" type="primary" native-type="submit">登录</van-button>
    </van-form>
  </page-container>
</template>

<script>
import { getTokenService, ipQuerySave, oldUserLogin } from '@/api/system';
import { SAVE_TOKEN, SAVE_USER_ID } from '@/util/storage';
import { getLocationSearch } from '@/util';
import { bindUserByInvitationCode } from '@/api/user';
import { signContract2_1 } from '@/components/SignContract';
import { Toast } from 'vant';

export default {
  name: 'qrCodeLogin',
  data() {
    return {
      showLogin: false,
      oldUserLoginForm: {
        username: '',
        pwd: '',
        openid: '',
        grant_type: 'older-user',
        scope: 'server',
      },
    };
  },
  created() {
    console.log(`qrCodeLogin`)

    let code = getLocationSearch().code;

    getTokenService({
      grant_type: 'older-user',
      scope: 'server',
      code,
    }).then(res => {
      let { msg, access_token, user_id } = res.data || {};
      if (access_token) {
        SAVE_TOKEN(access_token);
        SAVE_USER_ID(user_id);
        ipQuerySave();
        this.goNextPage();
      } else {
        let hasOpenid = msg.indexOf('openid:') > -1;
        if (hasOpenid) {
          this.oldUserLoginForm.openid = msg.split('openid:')[1];
          this.showLogin = true;
        } else {

          window.location.href = location.origin + '/shop-mobile/oauth/login?redirectUrl=' + encodeURIComponent(window.location.href);
        }
      }
    });
  },
  methods: {

    // http://test.ytjixiang.cn/auth/oauth/token?grant_type=older-user&scope=server&code=041ugFkl2TNKY84v1wnl2t1COl3ugFkK
    // 成功

    //报错 {msg} openid:开头   账号 密码 openid grant_type=older-user  scope: 'server',


    oldUserLogin() {
      getTokenService({ ...this.oldUserLoginForm }).then( async res => {
        let { msg, access_token, user_id } = res.data || {};
        if (user_id) {
          SAVE_TOKEN(access_token);
          SAVE_USER_ID(user_id);

          await signContract2_1(); //合同2-1签署

          // window.location.href = location.href.split('?')[0] + '#/home';
          this.goNextPage();
        } else {
          Toast(msg);
        }
      });
    },

    goNextPage(path = '/home', query = {}) {
      this.$router.replace({
        path,
        query,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.old-user-login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;

  .van-form {
    margin: 20px 0 0 0;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
  }

  .van-button {
    margin-top: 20px;
  }
}
</style>

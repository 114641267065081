import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import homeHome from '@/store/modules/homeHome';
import homeGoods from '@/store/modules/homeGoods';
import homeMy from '@/store/modules/homeMy';
import receiverAddress from '@/store/modules/reciverAddress';
import groupGoodsList from '@/store/modules/groupGoodsList';
import payment from '@/store/modules/payment';
import orderList from '@/store/modules/orderList';
import afterSaleList from '@/store/modules/afterSaleList';
import sendBack from '@/store/modules/sendBack';
import normalGoodsDetail from '@/store/modules/normalGoodsList';
import warehouse from '@/store/modules/warehouse';
import homeCustomerService from '@/store/modules/homeCustomerService';
import integralGoods from '@/store/modules/integralGoods';
import integralPayment from '@/store/modules/integralPayment';
import integralOrderList from '@/store/modules/integralOrderList';
import integralAfterSaleList from '@/store/modules/integralAfterSaleList';
import integralSendBack from '@/store/modules/integralSendBack';

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    homeHome,
    homeGoods,
    homeMy,
    receiverAddress,
    groupGoodsList,
    payment,
    orderList,
    afterSaleList,
    sendBack,
    normalGoodsDetail,
    warehouse,
    homeCustomerService,
    integralGoods,
    integralPayment,
    integralOrderList,
    integralAfterSaleList,
    integralSendBack,
  }
});

import request from '@/plugins/axios';

/**
 * 普通订单信息
 */
export function getNormalGoodsOrderInfo(params) {
  return request({
    url: `shop-mobile/goodsOrder/getGoodsInfo/${ params.goodsId }`,
    method: 'post',
    params,
  });
}

/**
 * 获取所有类型订单数据
 */
export function getAllOrdersByPage(params) {
  return request({
    url: `shop-mobile/goodsOrder/page`,
    method: 'get',
    params,
  });
}


export function getAllOrdersByMainPage(params) {
  return request({
    url: `shop-mobile/goodsOrder/mainPage`,
    method: 'get',
    params,
  });
}

/**
 * 获取订单数据(id查询)
 */
export function getOrderInfoById(params) {
  return request({
    url: `shop-mobile/goodsOrder/${ params.id }`,
    method: 'get',
    params,
  });
}

/**
 * 确认订单
 */
export function normalSaveOrder(data) {
  return request({
    url: `shop-mobile/goodsOrder/saveOrder`,
    method: 'post',
    data
  });
}

/**
 * app商品wx再次支付
 */
export function normalOrderPayAgain(data, opts = {}) {
  return request({
    url: `shop-mobile/goodsOrder/payAgain/${ data.id }`,
    method: 'post',
    data,
    ...opts
  });
}


/**
 * 用户余额支付 / app商品wx支付
 */
export function userBalancePayment(data, opts = {}) {
  return request({
    url: `shop-mobile/goodsOrder/payOrder/${ data.id }`,
    method: 'post',
    data,
    ...opts
  });
}

/**
 * pc支付二维码跳转到app支付
 */
export function pcQrCodeToWxPay(params) {
  return request({
    url: `shop-mobile/wxPay/qrPay/${ params.id }`,
    method: 'get',
    params,
  });
}


/**
 * 删除订单
 */
export function deleteOrder(data) {
  return request.delete('shop-mobile/goodsOrder/' + data.id, {}, {
    login: false,
  });
}

/**
 * 取消订单（维权完成）
 */
export function adviceDoneOrder(data) {
  return request.post('shop-mobile/goodsOrder/adviceDoneOrder?orderId=' + data.id, {
    login: false,
  });
}

/**
 * 维权申请
 */
export function safeguardRights(data) {
  return request({
    url: `shop-mobile/goodsOrder/adviceOrder`,
    method: 'post',
    data
  });
}

/**
 * 取消维权
 */
export function adviceCancelOrder(data) {
  return request({
    url: `shop-mobile/goodsOrder/adviceCancelOrder`,
    method: 'post',
    data
  });
}


/**
 * 取消订单（订单完成）
 * 参数：orderId
 */
export function cancelOrder(data) {
  return request({
    url: `shop-mobile/goodsOrder/cancelOrder`,
    method: 'post',
    params: data,
  });
}

/**
 * 提前确认收货
 * 参数： orderId
 */
export function confirmOrder(data) {
  return request({
    url: `shop-mobile/goodsOrder/confirmOrder`,
    method: 'post',
    params: data,
  });
}

/**
 * 商品邮费
 */
export function getOrderAreaPostage(params = {}) {
  return request({
    url: `shop-mobile/goodsOrder/getOrderAreaPostage`,
    method: 'get',
    params,
  });
}


/**
 * 查询物流（根据订单号）
 */
export function queryExpress(data, opts) {
  return request({
    url: `shop-mobile/goodsOrder/queryMoreExpress/${ data.id }`,
    method: 'get',
    data,
    ...opts
  });
}

/**
 * 获取退货地址
 */
export function getBackAddress(params) {
  return request({
    url: `shop-mobile/goodsOrder/sendBackAddress`,
    method: 'get',
    params,
  });
}

/**
 * 获取物流公司
 */
export function getExpressList(params) {
  return request({
    url: `shop-mobile/goodsOrder/expressList`,
    method: 'get',
    params,
  });
}

/**
 * 发送退货单号
 */
export function sendOrderBack(params) {
  return request({
    url: `shop-mobile/goodsOrder/sendBack/${ params.orderReturnId }`,
    method: 'get',
    params,
  });
}

/**
 * 用户申请发票
 */
export function userApplyInvoice(orderId) {
  return request({
    url: `shop-mobile/openInvoice/userApplyInvoice/${ orderId }`,
    method: 'get',
    headers: { getAllResponse: true }
  });
}

/**
 * 规格查询
 */
export function judgmentSpec(params) {
  return request({
    url: `shop-mobile/goods/goodsInfo/judgmentSpec`,
    method: 'get',
    params,
  });
}

/**
 * 快捷支付银行卡列表
 */
export function getFastCards(params = {}) {
  return request({
    url: `shop-mobile/user/fastCards`,
    method: 'get',
    params,
  });
}

/**
 * 再次发送快捷支付银行验证码
 */
export function resendFastPaySmsCode(params = { paymentId: null}, opts = {}) {
  return request({
    url: `shop-mobile/goodsOrder/resend/fastPay`,
    method: 'get',
    params,
    ...opts,
  });
}

/**
 * 快捷支付
 */
export function fastPay(data = {}, opts = {}) {
  return request({
    url: `shop-mobile/goodsOrder/confirm/fastPay`,
    method: 'put',
    params: data,
    ...opts,
  });
}

/**
 * 扫码跳转的快捷支付
 */
export function qrCodeFastPay(data = {}, opts = {}) {
  return request({
    url: `shop-mobile/fastPay/qrPay/${data.orderNo}`,
    method: 'get',
    params: data,
    ...opts,
  });
}


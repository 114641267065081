<!-- copy by ant-design-vue -->
<template>
  <div ref="placeholderNode" :style="placeholderStyle">
    <div ref="fixedNode" :style="affixStyle">
      <slot/>
    </div>
  </div>
</template>

<script>
import {
  throttleByAnimationFrame,
  addObserveTarget,
  removeObserveTarget,
  getTargetRect,
  getFixedTop,
  getFixedBottom
} from './utils';

const getDefaultTarget = () => typeof window !== 'undefined' ? window : null;

const AffixStatus = {
  None: 'none',
  Prepare: 'Prepare'
};

export default {
  name: 'AffixItem',
  props: {
    // 距离窗口顶部达到指定偏移量后触发
    offsetTop: { default: () => undefined },
    // 距离窗口底部达到指定偏移量后触发
    offsetBottom: { default: () => undefined },
    // 设置 Affix 需要监听其滚动事件的元素，值为一个返回对应 DOM 元素的函数
    target: { default: () => getDefaultTarget },
  },
  data() {
    return {
      affixStyle: undefined,
      placeholderStyle: undefined,
      status: AffixStatus.None,
      lastAffix: false,
      prevTarget: null,
    };
  },
  beforeMount() {
    this.updatePosition = throttleByAnimationFrame(this.updatePosition);
    this.lazyUpdatePosition = throttleByAnimationFrame(this.lazyUpdatePosition);
  },
  mounted() {
    let { target } = this;
    if (target) {
      this.timeout = setTimeout(() => {
        addObserveTarget(target(), this);
        this.updatePosition();
      });
    }
  },
  updated() {
    this.measure();
  },
  watch: {
    target(val) {
      let newTarget = null;
      if (val) {
        newTarget = val() || null;
      }
      if (this.prevTarget !== newTarget) {
        removeObserveTarget(this);
        if (newTarget) {
          addObserveTarget(newTarget, this);
          // Mock Event object.
          this.updatePosition();
        }
        this.prevTarget = newTarget;
      }
    },
    offsetTop() {
      this.updatePosition();
    },
    offsetBottom() {
      this.updatePosition();
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
    removeObserveTarget(this);
    this.updatePosition.cancel();
    this.lazyUpdatePosition.cancel();
  },

  methods: {
    getOffsetTop() {
      let { offsetTop, offsetBottom } = this;

      if (offsetTop === undefined && offsetBottom === undefined)
        offsetTop = 0;

      return offsetTop;
    },
    getOffsetBottom() {
      return this.offsetBottom;
    },
    setState() {
      let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let callback = arguments[1];

      let newState = typeof state === 'function' ? state(this.$data, this.$props) : state;
      Object.assign(this.$data, newState);
      this.$forceUpdate();
      this.$nextTick(() => {
        callback && callback();
      });
    },

    // =================== Measure ===================
    measure() {
      let { status, lastAffix, target } = this;

      if (status !== AffixStatus.Prepare || !this.$refs.fixedNode || !this.$refs.placeholderNode || !target)
        return;

      let offsetTop = this.getOffsetTop();
      let offsetBottom = this.getOffsetBottom();

      let targetNode = target();
      if (!targetNode)
        return;

      let newState = {
        status: AffixStatus.None
      };
      let targetRect = getTargetRect(targetNode);
      let placeholderReact = getTargetRect(this.$refs.placeholderNode);
      let fixedTop = getFixedTop(placeholderReact, targetRect, offsetTop);
      let fixedBottom = getFixedBottom(placeholderReact, targetRect, offsetBottom);
      if (fixedTop !== undefined) {
        newState.affixStyle = {
          position: 'fixed',
          top: fixedTop,
          width: placeholderReact.width + 'px',
          height: placeholderReact.height + 'px'
        };
        newState.placeholderStyle = {
          width: placeholderReact.width + 'px',
          height: placeholderReact.height + 'px'
        };
      } else if (fixedBottom !== undefined) {
        newState.affixStyle = {
          position: 'fixed',
          bottom: fixedBottom,
          width: placeholderReact.width + 'px',
          height: placeholderReact.height + 'px'
        };
        newState.placeholderStyle = {
          width: placeholderReact.width + 'px',
          height: placeholderReact.height + 'px'
        };
      }

      newState.lastAffix = !!newState.affixStyle;
      if (lastAffix !== newState.lastAffix) {
        this.$emit('change', newState.lastAffix);
      }

      this.setState(newState);
    },
    prepareMeasure() {
      this.setState({
        status: AffixStatus.Prepare,
        affixStyle: undefined,
        placeholderStyle: undefined
      });
      this.$forceUpdate();
    },
    updatePosition() {
      this.prepareMeasure();
    },
    lazyUpdatePosition() {
      let { target, affixStyle } = this;
      // Check position change before measure to make Safari smooth
      if (target && affixStyle) {
        let offsetTop = this.getOffsetTop();
        let offsetBottom = this.getOffsetBottom();

        let targetNode = target();
        if (targetNode && this.$refs.placeholderNode) {
          let targetRect = getTargetRect(targetNode);
          let placeholderReact = getTargetRect(this.$refs.placeholderNode);
          let fixedTop = getFixedTop(placeholderReact, targetRect, offsetTop);
          let fixedBottom = getFixedBottom(placeholderReact, targetRect, offsetBottom);

          if (fixedTop !== undefined && affixStyle.top === fixedTop || fixedBottom !== undefined && affixStyle.bottom === fixedBottom) {
            return;
          }
        }
      }
      // Directly call prepare measure since it's already throttled.
      this.prepareMeasure();
    },
  },
};
</script>

import { getSDK } from '@/api/system';
import $wxJs from '@/util/jweixin-module/lib/index.js';
import { getStore } from '@/api/shop';
import { getUserInfo } from '@/api/user';
import { GET_LOGIN_REFEREE_ID, GET_USER_ID } from '@/util/storage';
import { VUE_APP_API_URL, VUE_APP_SHARE_URL } from '@/config';
import { Toast } from 'vant';

export function wxShare(opts) {

  getSDK().then(res => {
    let { appId, timestamp, nonceStr, signature } = res || {};
    if (!appId)
      return;

    $wxJs.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId, // 必填，公众号的唯一标识
      timestamp, // 必填，生成签名的时间戳
      nonceStr, // 必填，生成签名的随机串
      signature, // 必填，签名
      jsApiList: [
        'updateAppMessageShareData',
        'updateTimelineShareData',
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
        'onMenuShareQQ',
        'onMenuShareWeibo',
        'onMenuShareQZone',
        'showMenuItems',
      ] // 必填，需要使用的JS接口列表
    });

    $wxJs.ready(() => {

      $wxJs.showMenuItems({
        menuList: [
          'menuItem:share:appMessage',
          'menuItem:share:timeline',
        ] // 要显示的菜单项，所有menu项见附录3
      });

      $wxJs.checkJsApi({
        jsApiList: [
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
        ],
        success: (res) => {

          let {
            title = '', desc = '', link = '', imgUrl = '', type = '', dataUrl = '', success = () => {
            }
          } = opts || {};

          if (res.errMsg === 'checkJsApi:ok') {
            // title 分享标题
            // desc 分享描述
            // link 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            // imgUrl 分享图标
            // type 分享类型,music、video或link，不填默认为link
            // dataUrl 如果type是music或video，则要提供数据链接，默认为空
            $wxJs.onMenuShareAppMessage({
              title, desc, link, imgUrl, type, dataUrl, success
            });
            $wxJs.onMenuShareTimeline({
              title, link, imgUrl, success
            });
          } else {
            $wxJs.updateAppMessageShareData({
              title, desc, link, imgUrl, type, dataUrl, success
            });
            $wxJs.updateTimelineShareData({
              title, link, imgUrl, success
            });
          }
        }
      });
    });

  });
}

export function wxShareByStore() {
  let shareUrl = VUE_APP_SHARE_URL + '?';
  let imgUrlBaseUrl = VUE_APP_API_URL;
  let refereeId = GET_LOGIN_REFEREE_ID();
  let userId = GET_USER_ID();
  let userInfo = {};
  let storeInfo = {};

  let promise1 = getStore().then(res => {
    storeInfo = res || {};
  });

  let promise2 = getUserInfo().then(res => {
    userInfo = res || {};
  });

  Promise.all([promise1, promise2]).then(() => {
    let shareInvitationCode = `invitationCode=${userInfo.invitationCode}`; // 分享邀请码
    shareUrl += `${shareInvitationCode}&`;

    // let refereeIdParam = `refereeId=${refereeId}`; // 商品店铺id
    // let betweenIdParam = `betweenId=${userId}`; // 登录用户id
    //
    // let level = userInfo.level || '';
    // // 分享地址拼接
    // if (+level === 1) {
    //   shareUrl += ``;
    // } else if (+level === 2) {
    //   shareUrl += `${refereeIdParam}&${betweenIdParam}`;
    // } else {
    //   shareUrl += `${refereeIdParam}&${betweenIdParam}`;
    // }

    let { name, memo, logo } = storeInfo;
    sessionStorage.setItem('shopShareLink', shareUrl);
    wxShare({
      title: name || '',
      desc: memo || '',
      link: shareUrl,
      imgUrl: logo ? imgUrlBaseUrl + logo : '',
      success: () => {
        Toast('分享成功');
      }
    });
  });

}


export function hideShare() {
  getSDK().then(res => {
    let { appId, timestamp, nonceStr, signature } = res || {};
    if (!appId)
      return;

    $wxJs.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId, // 必填，公众号的唯一标识
      timestamp, // 必填，生成签名的时间戳
      nonceStr, // 必填，生成签名的随机串
      signature, // 必填，签名
      jsApiList: [
        'hideMenuItems',
      ] // 必填，需要使用的JS接口列表
    });

    $wxJs.ready(() => {
      $wxJs.hideMenuItems({
        menuList: [
          'menuItem:share:appMessage',
          'menuItem:share:timeline',
          'menuItem:share:qq',
          'menuItem:share:weiboApp',
          'menuItem:favorite',
          'menuItem:share:facebook',
          'menuItem:share:QZone',
        ] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
      });
    });

  });

}

import request from '@/plugins/axios';




/**
 * 购物车分页查询
 */
 export function getGoodsCart(params) {
    return request({
      url: 'shop-mobile/goodsCart/page',
      method: 'get',
      params
    });
  }

/**
 * 新增购物车
 */
export function addGoodsCart(data) {
  return request({
    url: 'shop-mobile/goodsCart',
    method: 'post',
    data
  });
}

/**
 * 修改购物车
 */
 export function editGoodsCart(data) {
    return request({
      url: 'shop-mobile/goodsCart',
      method: 'put',
      data
    });
  }

  /**
 * 删除购物车
 */
 export function deleteGoodsCart(id) {
    return request({
      url: 'shop-mobile/goodsCart/'+id,
      method: 'delete',
    });
  }


  /**
 * 购物车结算页面
 */
   export function getCartGoodsInfo(cartIds) {
    return request({
      url: '/shop-mobile/goodsOrder/getCartGoodsInfo?cartIds='+cartIds,
      method: 'get',
    });
  }


  /**
 * 购物车商品邮费
 */
export function getCartOrderAreaPostage(data) {
  return request({
    url: `shop-mobile/goodsOrder/getCartOrderAreaPostage`,
    method: 'post',
    data,
  });
}


  /**
 * 提交订单
 */
   export function saveCartOrder(data) {
    return request({
      url: `shop-mobile/goodsOrder/saveCartOrder`,
      method: 'post',
      data,
    });
  }


    /**
 * 订单详情
 */
     export function getMainOrderDetail(orderNo) {
      return request({
        url: `shop-mobile/goodsOrder/getMainOrderDetail?orderNo=${orderNo}`,
        method: 'get',
      });
    }


        /**
 * 取消订单
 */
    export function cancelMainOrder(orderNo) {
      return request({
         url: `shop-mobile/goodsOrder/cancelMainOrder?orderNo=${orderNo}`,
         method: 'get',
      });
    }
    
  // 支付接口
    export function payOrder(orderNo,opts = {}) {
      return request({
         url: `shop-mobile/goodsOrder/payOrder2?orderNo=${orderNo}`,
         method: 'post',
         ...opts,
      });
    }
    

    /**
 * app商品wx再次支付   购物车下单
 */
export function payAgain2(orderNo,opts = {}) {
  return request({
    url: `shop-mobile/goodsOrder/payAgain2?orderNo=${orderNo}`,
    method: 'post',
    ...opts,
  });
}
  



<template>
  <page-container
      ref="pageContainer"
      :scroll-top.sync="scrollTop"
  >
    <!-- 下拉刷新 -->
    <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        style="position: relative;z-index: 1"
    >
      <div class="banner-background"></div>
      <van-sticky
          :container="container"
          @change="top => {this.topSearchIsFixed = top}"
      >
        <van-search
            :class="{ topSearchIsFixed }"
            :readonly="true"
            shape="round"
            show-action
            placeholder="搜索商品"
            :clearable="true"
            @click="handleSearchGoods"
        >
          <template #action>
            <div class="system-notice" :class="{ 'empty-msg': !unreadCount }">
              <van-badge :content="unreadCount">
                <van-icon
                    name="bell"
                    @click="handleSystemNoticeClick"
                />
              </van-badge>
            </div>
          </template>
        </van-search>
      </van-sticky>
      <!-- 广告 -->
      <div class="home-img-swiper-wrap">
        <div class="swiper-container">
          <van-swipe
              style="height: 100%"
              :autoplay="5000"
          >
            <van-swipe-item
                v-for="({ imageUrl, href }, index) of imageList"
                :key="index"
                @click="handleAdClick(href)"
            >
              <van-image
                  :src="imageUrl"
                  fill="cover"
              />
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
      <!-- 商品分类 新 -->
      <div class="goods-class-list-container">
        <div
            v-for="goodsClass in goodsClassFormatList"
            :key="goodsClass.key"
            :class="goodsClass.className"
            @click="handleClassifyGoodsList(goodsClass.id, goodsClass.routePath)"
            :style="{
              background: `url(homeImg/home-class/${goodsClass.bgIndex}.png) center no-repeat`,
              backgroundSize: '100.3% 100.3%',
            }"
        >
          <div>{{ goodsClass.name }}</div>
        </div>
      </div>


      <!-- 商品分类 旧 -->
      <!--      <van-grid-->
      <!--          :column-num="3"-->
      <!--          :border="false"-->
      <!--          class="goods-class-container"-->
      <!--      >-->
      <!--        <van-grid-item-->
      <!--            v-for="goodsClass in goodsClassAddIntegralList"-->
      <!--            :text="goodsClass.name"-->
      <!--            :key="goodsClass.id"-->
      <!--            @click="handleClassifyGoodsList(goodsClass.id, goodsClass.routePath)"-->
      <!--        >-->
      <!--          <template #icon>-->
      <!--            <van-icon-->
      <!--                v-if="goodsClass.id === '$integral$'"-->
      <!--                name="points"-->
      <!--                class="grid-integral-item"-->
      <!--            />-->
      <!--            <van-image-->
      <!--                v-else-->
      <!--                :src="goodsClass.icon"-->
      <!--                fill="cover"-->
      <!--            />-->
      <!--          </template>-->
      <!--        </van-grid-item>-->
      <!--      </van-grid>-->

      <div class="home-home-goods-list-container">
        <!-- 更多列表加载 -->
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
        >
          <!-- 商品 -->
          <goods-info
              v-for="item in dataList"
              view-model="column"
              :key="item.id"
              @click="handleGoodsClick(item)"
              :value="{
              goodsImgSrc: item.thumbImageUrl,
              goodsName: item.name,
              goodsDesc: '',
              goodsPrice: item.goodsPrice,
            }"
          />
        </van-list>
      </div>
    </van-pull-refresh>
  </page-container>
</template>

<script>
import { mapState } from 'vuex';
import { getAdCarouselPageList, getPageAllNormalGoods, shopSupplierRecommendList } from '@/api/normalGoods';
import GoodsInfo from '@/components/GoodsInfo';
import { getGoodsClassifyList } from '@/api/normalGoods';
import { pagingQueryMessage } from '@/api/sessionMessage';

export default {
  name: 'home',
  components: { GoodsInfo },
  data() {
    return {
      scrollTop: 0,
      refreshing: false,
      loading: false,
      finished: false,
      dataList: [],
      pagination: {
        // 分页参数
        pageNum: 0,
        pageSize: 10,
        total: 0,
      },

      container: null,
      topSearchIsFixed: false,

      unreadCount: 0,

      imageList: [
        // { imageUrl: 'homeImg/g1.jpg', href: 'www.baidu.com' },
        // { imageUrl: 'homeImg/g2.jpg' },
        // { imageUrl: 'homeImg/g3.jpg' },
        // { imageUrl: 'homeImg/banner.png' },
      ],
      goodsClassList: [], // 商品分类
    };
  },
  computed: {
    ...mapState({
      storeRefreshing: state => state.homeHome.refreshing,
    }),
    goodsClassAddIntegralList() {
      let { goodsClassList } = this;
      return [
        ...goodsClassList,
        { id: '$integral$', name: '积分商城', routePath: '/integralGoods' },
      ];
    },
    goodsClassFormatList() {
      let { goodsClassList } = this;
      if (!goodsClassList || !goodsClassList.length)
        return [];

      const nameMap = [{}, ...goodsClassList, { id: '$integral$', name: '积分商城' }].reduce((map, clazz) => ({
        ...map,
        [clazz.name]: { ...clazz },
      }));

      return [
        { name: '生态制剂', className: 'big-class-wrap', bgIndex: 1 },
        { name: '无糖空间', className: 'big-class-wrap', bgIndex: 2 },
        { name: '联盟商品', className: 'small-class-wrap', bgIndex: 3 },
        { name: '阳光订单', className: 'small-class-wrap', bgIndex: 4 },
        { name: '商学院', className: 'small-class-wrap', bgIndex: 5, routePath: '/integralGoods' },
      ].map(clazz => {
        const { id = '' } = nameMap[clazz.name] || {};
        return { id, key: id || clazz.name, ...clazz };
      });
    }
  },
  created() {
    this.$watch('storeRefreshing', () => {
      if (!this.storeRefreshing)
        return;
      this.$store.commit('homeHome/setRefreshing', false);
      this.onRefresh();
    }, { immediate: true });
  },
  mounted() {

    shopSupplierRecommendList();

    this.container = this.$refs.pageContainer;
  },
  activated() {
    this.$refs.pageContainer.scrollToTop(this.scrollTop);
  },
  methods: {
    onLoad() {
      // this.pagination.pageNum++;
      // let {
      //   searchData,
      //   pagination: { pageNum: current, pageSize: size },
      // } = this;

      // return getPageAllNormalGoods({
      //   ...searchData,
      //   current,
      //   size,
      // }).then(data => {
      //   this.dataPageLoadingSuccess();
      //   let { records: dataList = [], total = 0 } = data || {};
      //   this.dataList = [...this.dataList, ...dataList];
      //   this.pagination.total = total || 0;
      //   if (this.dataList.length >= this.pagination.total || !dataList.length)
      //     this.finished = true;
      // });

      return shopSupplierRecommendList().then(data => {
        this.dataPageLoadingSuccess();
        this.dataList = Array.isArray(data) ? data : [];
        this.pagination.total = this.dataList.length;
        this.finished = true;
      });
    },
    onRefresh() {
      getAdCarouselPageList().then(res => {
        let data = res || {};
        this.imageList = data.records || [];
      });

      getGoodsClassifyList().then(res => {
        this.goodsClassList = res || [];
      });
      this.queryMessageCount();

      // 清空列表数据
      this.dataList = [];
      this.pagination.pageNum = 0;
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      return this.onLoad();
    },
    dataPageLoadingSuccess() {
      this.loading = false;
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
    },

    handleAdClick(href) {
      if (href)
        window.location.href = href;
      // href && window.open(href);
    },
    handleGoodsClick(obj) {
      this.$router.push({
        path: '/goodsDetail',
        query: {
          normalGoodsId: obj.goodsId,
        },
      });
    },
    handleClassifyGoodsList(classifyId, routePath) {
      if (routePath) {
        this.$store.commit('integralGoods/setRefreshing', true);
        this.$router.push('/integralGoods');
      } else {
        this.$store.commit('normalGoodsDetail/setRefreshing', true);
        this.$router.push({
          path: '/normalGoodsList',
          query: { classifyId }
        });
      }
    },
    // 获取未读系统消息数量
    queryMessageCount() {
      pagingQueryMessage({ messageType: '02' }).then(res => {
        res = res || {};
        this.unreadCount = res.unreadCount || 0;
      });
    },
    // 商品搜索
    handleSearchGoods() {
      this.$router.push({
        path: '/searchGoods'
      });
    },
    // 系统消息
    handleSystemNoticeClick() {
      this.$router.push({
        path: '/customerService',
        query: {
          messageType: '02'
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.banner-background {
  position: absolute;
  z-index: 1;
  top: 0;
  left: -25%;
  height: 155px;
  width: 150%;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#f1503b), color-stop(50%, @primary-color));
  background-image: -webkit-linear-gradient(bottom, #f1503b, @primary-color 50%);
  background-image: linear-gradient(0deg, #f1503b, @primary-color 50%);
}

.van-search {
  position: relative;
  z-index: 2;
  padding: 7px 0 7px 10px;
  background: #0000;

  &.topSearchIsFixed {
    background-color: @primary-color;
  }

  .van-cell {
    line-height: 21px;
  }

  .van-search__action:active {
    background-color: #0000;
  }

  /deep/ .van-field__left-icon {
    color: #999;
  }
}

.home-img-swiper-wrap {
  position: relative;
  width: 100%;
  padding-top: 40%;

  .swiper-container {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .van-swipe-item {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      box-sizing: border-box;
      padding: 0 12px;

      .van-image {
        height: 100%;
        border-radius: 12px;
        overflow: hidden;
      }
    }

    /deep/ .van-swipe__indicator--active {
      bottom: 7px;
      width: 12px;
      border-radius: 4px;
      transition: width .2s;
    }
  }
}

.system-notice {
  padding: 0 4px;
  font-size: 22px;
  color: #fff;

  /deep/ .van-badge {
    top: 3px;
  }

  /deep/ &.empty-msg .van-badge {
    display: none;
  }
}

.goods-class-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 12px;
  background-color: #fff;
  padding: 13px 10px 8px 10px;
  border-radius: 15px;
  overflow: hidden;

  .big-class-wrap, .small-class-wrap {
    margin: 0 0 7px 0;
    border-radius: 4px;
    overflow: hidden;

    & > div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      color: #fff;
    }
  }

  .big-class-wrap {
    position: relative;
    z-index: 1;
    padding-top: 18%;
    width: 49%;
    box-shadow: -1.5px 1.5px 1.5px 0 #ccc;

    & > div {
      padding-right: 24px;
      font-size: 14px;
    }
  }

  .small-class-wrap {
    position: relative;
    padding-top: 15%;
    width: 32%;

    & > div {
      padding-right: 8px;
      font-size: 13px;
    }
  }

}

.goods-class-container {
  margin: 12px;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;

  .van-grid-item__icon-wrapper {
    .van-image {
      width: 36px;
      height: 36px;
    }
  }

  /deep/ .van-grid-item__content.van-grid-item__content--center {
    padding: 8px 0;
  }

  /deep/ .van-grid-item__text {
    font-size: 14px;
  }

  .grid-integral-item {
    font-size: 36px;
    color: #666;

    &:before {
      font-size: 28px;
    }
  }
}

.home-home-goods-list-container {
  padding: 2px 12px 12px 12px;
}
</style>

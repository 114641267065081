export default {
  namespaced: true,

  state: {
    refreshing: true,
  },
  getters: {
  },
  mutations: {
    setRefreshing(state, payload) {
      state.refreshing = payload;
    },
  },
  actions: {
  },
};

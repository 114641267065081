import request from '@/plugins/axios';

/**
 * 用户登录
 */
export function getTokenService(query) {
  return request({
    url: 'auth/oauth/token',
    method: 'get',
    headers: {
      notNeedToken: true, // 是否不需要token。默认需要。
      getAllResponse: true, // 获取接口参数，包含code、msg、data等等。默认只返回data，不包含code、msg。
    },
    params: query
  });
}

/**
 * 旧用户登录
 */
export function oldUserLogin(query) {
  return request({
    url: 'shop-mobile/user/oldUser',
    method: 'get',
    // headers: {
    //   notNeedToken: true,
    // },
    params: query
  });
}

/**
 *获取微信sdk配置
 */
export function getSDK(params = {}) {
  return request({
    url: 'shop-mobile/user/wxConfig',
    method: 'get',
    headers: { noErrorTip: true },
    params
  });
}

/**
 * wx订单创建
 * /wxPay/unifiedOrder
 */
export function wxPayUnifiedOrder(data = {}) {
  return request({
    url: 'shop-mobile/wxPay/unifiedOrder',
    method: 'post',
    data
  });
}

/**
 * 商学院单点登录跳转地址获取
 */
export function getToSchoolUrl(params = {}) {
  return request({
    url: 'shop-mobile/to_school',
    method: 'get',
    params
  });
}

/**
 * 登录成功调用，记录某区域用户登录的次数
 */
export function ipQuerySave() {
  return request({
    url: `shop-mobile/ipQuery/ipQuerySave`,
    headers: { noErrorTip: true },
    method: 'get',
  });
}

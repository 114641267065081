import request from '@/plugins/axios';

/**
 * 创建消息
 */
export function createMessage(data) {
  return request({
    url: `shop-mobile/message/addMessage`,
    method: 'put',
    data,
  });
}
/**
 * 查询消息{id查询}
 */
export function idQueryMessage(data) {
  return request.get('shop-mobile/message/getById/'+ data.id ,{}, {
    login: false,
  })
}
/**
 * 查询消息{分页查询}
 */
export function pagingQueryMessage(params) {
  return request({
    url: `shop-mobile/message/page`,
    method: 'get',
    params,
  });
}
/**
 * 删除消息
 */
export function removalMessage(data) {
  return request.delete('shop-mobile/message/removeById/'+ data.id,{},{
    login: false,
  })
}

/**
 * 发送图片消息
 */
export function uploadImg(data) {
  return request({
    url: `shop-mobile/message/addMessagePicture`,
    method: 'post',
    data,
  });
}

<template>
  <div class="user-login-container">
    <van-button
        type="info" round
        @click="newUserLogin"
    >
      新用户登录
    </van-button>

    <van-button
        type="primary" round
        @click="dialogOldUserLoginShow = true"
    >
      老用户登录
    </van-button>


    <van-dialog
        v-model="dialogOldUserLoginShow"
        title="老用户登录"
        :show-confirm-button="false"
    >
      <van-form
          @submit="oldUserLogin"
          label-width="4.5em"
          style="margin-top: 2em"
      >
        <div class="form-card">
          <van-field
              v-model.trim="oldUserLoginForm.username"
              name="username"
              label="用户名"
              placeholder="用户名"
              :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <van-field
              v-model.trim="oldUserLoginForm.password"
              name="password"
              label="密码"
              placeholder="密码"
              border
              :rules="[{ required: true, message: '请填写密码' }]"
          />
        </div>

        <div class="submit-container">
          <van-button round size="small" @click="dialogOldUserLoginShow = false">取消</van-button>

          <van-button round size="small" type="primary" native-type="submit">登录</van-button>
        </div>
      </van-form>

    </van-dialog>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant';
import { getTokenService, ipQuerySave, oldUserLogin } from '@/api/system';
import { SAVE_TOKEN, SAVE_USER_ID } from '@/util/storage';
import { getLocationSearch } from '@/util';
import { bindUserByInvitationCode } from '@/api/user';
import { signContract2_1 } from '@/components/SignContract/index.js';

export default {
  name: 'userLogin',
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  props: {
    uuid: {
      default: () => '',
    }
  },
  data() {
    return {
      dialogOldUserLoginShow: false,
      oldUserLoginForm: {
        username: '',
        password: '',
      },
    };
  },
  methods: {
    newUserLogin() {
      Dialog.confirm({
        title: `是否以新用户身份进行登录`,
        // message: '弹窗内容',
        confirmButtonText: '确认',
        beforeClose: (action, done) => {
          // 点击确认
          if (action === 'confirm') {
            getTokenService({
              uuid: this.uuid,
              grant_type: 'weixin-new',
              scope: 'server'
            }).then(res => {
              this.saveLoginInfo(res);
              done();
            });
          } else {
            // 点击取消
            done();
          }
        },
      }).catch(() => {
      });
    },
    oldUserLogin() {
      oldUserLogin({ ...this.oldUserLoginForm }).then(res => {
        let userId = res ? res.userId : '';
        if (userId) {
          getTokenService({
            uuid: this.uuid,
            userId,
            grant_type: 'weixin-new',
            scope: 'server'
          }).then(res => {
            this.saveLoginInfo(res);
          });
        }
      });
    },
    saveLoginInfo(res) {
      let { msg, access_token, user_id } = res.data || {};
      // 新/老用户登录失败提示
      if (!access_token)
        return Toast(msg);

      SAVE_TOKEN(access_token);
      SAVE_USER_ID(user_id);
      ipQuerySave();

      let { invitationCode, state, orderNo } = getLocationSearch();
      let path = state ? decodeURIComponent(state) || '/home' : '/home';

      if (path === '/pcWxQrCodePay') {
        path += `?orderNo=${orderNo}`
      }

      if (path === '/pcFastQrCodePay') {
        path += `?orderNo=${orderNo}`;
      }

      if (invitationCode)
        bindUserByInvitationCode({ invitationCode }).then(res => {
          this.goNextPage(path);
        });
      else
        this.goNextPage(path);
    },
    async goNextPage(path = '/home', query = {}) {
      await signContract2_1(); //合同2-1签署

      this.$router.replace({
        path,
        query,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.user-login-container {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > .van-button {
    margin: 20px;
    padding: 0 20px;
  }

  .submit-container {
    display: flex;
    justify-content: space-around;
    padding: 40px 20px 20px 20px;

    .van-button {
      width: 40%;
    }
  }

}
</style>

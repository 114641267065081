// 线上地址
export const VUE_APP_API_URL = process.env["VUE_APP_API_URL"];
export const VUE_APP_ADMIN_URL = process.env["VUE_APP_ADMIN_URL"];
export const VUE_APP_SHARE_URL = process.env["VUE_APP_SHARE_URL"];
export const VUE_APP_SCHOOL_URL = process.env["VUE_APP_SCHOOL_URL"];
// 线下地址
// export const VUE_APP_API_URL = '';
export const VUE_APP_RESOURCES_URL = '';
// 是否强制显示老用户登录 "0": 不强制显示 "1": 强制显示
export const VUE_APP_ALWAYS_SHOW_OLD_USER_LOGIN = process.env.VUE_APP_ALWAYS_SHOW_OLD_USER_LOGIN;

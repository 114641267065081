<template>

</template>

<script>
import { Dialog } from 'vant';

export default {
  name: 'signContract',
  methods: {
    signContract(opts) {
      let { contract, props, confirm, cancel } = opts || {};
      let {
        contractTitle = 'contractTitle',
        contractContent = 'contractContent',
        id = 'contractNum',
      } = props || {};
      let title = contract[contractTitle] || '';
      let message = contract[contractContent] || '';
      let serviceId = contract[id] || '';
      let loading = false;
      Dialog.confirm({
        title,
        message,
        confirmButtonText: '同意',
        confirmButtonColor: '#ff7510',
        showCancelButton: false,
        className: 'contract-container',
        beforeClose: (action, done) => {
          if (loading) return;
          if (action === 'confirm') {
            if (typeof confirm === 'function') {
              loading = true;
              confirm({
                id: serviceId,
                data: contract,
                done: () => {
                  loading = false;
                  done();
                }
              });
            }
          } else {
            if (typeof cancel === 'function') {
              cancel({
                id: serviceId,
                data: contract,
                done,
              });
            }
          }
        },
      }).catch(() => {
      });
      window.setTimeout(() => {
        this.$nextTick(() => {
          document.getElementsByClassName('contract-container')[0].scrollTop = 0;
        })
      })
    },
  },
};
</script>

<style lang="less">
.contract-container {
  top: 50%;
  max-height: 88vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .van-dialog__header {
    padding: 12px 14px;
    line-height: 1.2;
    text-align: left;
  }

  .van-dialog__message {
    padding: 10px 14px 25px 14px;
    text-align: left;

    max-height: unset;
    overflow-y: unset;
  }
}

</style>

import request from '@/plugins/axios';

/**
 * 静默登录获取token
 */
export function getToken(data) {
  return request.get('/auth/oauth/token', data, {
    login: false,
  });
}


/**
 *用户信息
 */
export function getUserInfo(params) {
  return request({
    url: 'shop-mobile/user/info',
    method: 'get',
    params
  });
}

/**
 *修改用户信息
 */
export function saveUser(data) {
  return request({
    url: 'shop-mobile/user/save',
    method: 'put',
    data
  });
}

/**
 *修改用户手机号
 */
export function save2User(data) {
  return request({
    url: 'shop-mobile/user/save2',
    method: 'put',
    data
  });
}

/**
 *实名认证
 */
export function userAuthentication(data, ops = {}) {
  return request({
    url: 'shop-mobile/user/userAuthentication',
    method: 'put',
    data,
    ...ops
  });
}

// 保存银行卡信息
export function saveUserBankCard(data = {}, opts = {}) {
  return request({
    url: `shop-mobile/user/saveBankCard `,
    method: 'put',
    data,
    ...opts,
  });
}

// 删除用户银行卡
export function delUserBankCard(params = {}, opts = {}) {
  return request({
    url: `shop-mobile/user/deleteBankCard`,
    method: 'get',
    params,
    ...opts,
  });
}

/**
 *获取银行开户列表
 */
export function getBankList(params) {
  return request({
    url: 'shop-mobile/user/getDictItemByType',
    method: 'get',
    params
  });
}

/**
 *获取绑定店铺信息
 */
export function storeInformation(data) {
  return request.post('shop-mobile/user/getAgency?refereeId=' + data.id, {
    login: false,
  });
}

/**
 *获取个人账户
 */
export function getUserAccount(data) {
  return request({
    url: `shop-mobile/user/getAccount`,
    method: 'post',
    data,
  });
}

/**
 *获取开通店铺信息
 */
export function openShopInfo(data) {
  return request({
    url: `shop-mobile/user/getOpenAgency`,
    method: 'post',
    data,
  });
}

/**
 *获取佣金
 */
export function getUserBilling(params) {
  return request({
    url: `shop-mobile/user/getBilling`,
    method: 'get',
    params,
  });
}

/**
 * 解锁推广金额收入详情
 */
export function getLockDetail(params) {
  return request({
    url: `shop-mobile/user/lockDetail`,
    method: 'get',
    params,
  });
}

/**
 *红包佣金记录
 */
export function getUserRed(params) {
  return request({
    url: `shop-mobile/user/getRed`,
    method: 'get',
    params,
  });
}

/**
 *推荐过的用户
 */
export function getUnderUser(params) {
  return request({
    url: `shop-mobile/user/getUnderUser`,
    method: 'get',
    params,
  });
}

/**
 * 推广列表-会员用户
 */
export function getChildLevel1(params) {
  return request({
    url: `shop-mobile/user/getChildLevel1`,
    method: 'get',
    params,
  });
}

/**
 * 推广列表-居间用户
 */
export function getChildLevel2(params) {
  return request({
    url: `shop-mobile/user/getChildLevel2`,
    method: 'get',
    params,
  });
}

/**
 * 推广列表- level 会员1 居间2 推广员3
 */
export function getChildUser(params) {
  return request({
    url: `shop-mobile/user/page`,
    method: 'get',
    params,
  });
}


/**
 * 分享绑定
 */
export function bindUserByInvitationCode(params) {
  return request({
    url: `shop-mobile/user/bindUser`,
    method: 'post',
    params,
  });
}

/**
 * 第三方支付银行地址
 * /userAddress/adapay
 */
export function getAdaPayAddress(params = {}) {
  return request({
    url: `shop-mobile/userAddress/adapay`,
    method: 'get',
    params,
  });
}

/**
 * 通过code绑定用户
 */
export function bindWxUser(params = {}) {
  return request({
    url: `shop-mobile/user/bindWxUser`,
    method: 'post',
    params,
  });
}

export function myAgreementUrl(ops = {}) {
  return request({
    url: `shop-mobile/contractInfo/myAgreement`,
    method: 'get',
    ...ops
  });
}

/*
http://yzkp.shop.payol.cn/shop/userContributionFlow/balance
http://yzkp.shop.payol.cn/shop/userContributionFlow/page
 */

// 当前贡献值
export function getUserContributionFlowBalance(ops = {}) {
  return request({
    url: `shop-mobile/userContributionFlow/balance`,
    method: 'get',
    ...ops
  });
}

// 贡献值记录
export function getUserContributionFlow(params = {}, ops = {}) {
  return request({
    url: `shop-mobile/userContributionFlow/page`,
    method: 'get',
    params,
    ...ops
  });
}

//快捷支付列表
export function getFastCards(params = {}, ops = {}) {
  return request({
    url: `shop-mobile/user/fastCards`,
    method: 'get',
    params,
    ...ops
  });
}

//新增快捷支付获取验证码，
export function addFastCardSms(data) {
  return request({
    url: `shop-mobile/user/fastCard`,
    method: 'post',
    data,
  });
}
//保存快捷支付
export function saveFastCard(applyId,smsCode ) {
  return request({
    url: 'shop-mobile/user/fastCard?applyId=' + applyId +'&smsCode=' + smsCode,
    method: 'put',
    // data
  });
}
//删除快捷支付
export function delFastCard(data) {
  return request({
    url: 'shop-mobile/user/fastCard?tokenNo=' + data,
    method: 'delete',
  });
}

<template>
  <page-container
      class="my-container"
      ref="pageContainer"
      :scroll-top.sync="scrollTop"
  >
    <div class="user-info-container">
      <div class="user-info">
        <van-image
            class="user-avatar"
            :src="userInfo.avatar"
            round
        />

        <div class="user-money">
          <div>{{ userInfo.nickname }}</div>
          <!--          <p><span>余额：{{ accountInfo.amount }}</span><span>冻结：{{ accountInfo.lockAmount }}</span></p>-->
          <!--          <p><span>积分：{{ accountInfo.point }}</span></p>-->
          <p>ID：{{ userInfo.id }}</p>
          <p>&nbsp;</p>
          <div class="user-info-arrow" @click="editUserInfo">
          <van-icon name="arrow" />
        </div>
          <div style="display: flex;">
            <ul style="flex: 1">
              <li v-for="levelName in userLevelNameArr">{{ levelName }}</li>
            </ul>
            <!--            <van-button-->
            <!--                style="flex-shrink: 0;background: linear-gradient(to right, #dcad6a, #b28c57);color: #f6efe5;"-->
            <!--                type="primary" size="small"-->
            <!--                @click="$router.push('/depositOrderList')"-->
            <!--            >-->
            <!--              充值订单-->
            <!--            </van-button>-->
          </div>
        </div>
      </div>

      <div class="link-item" @click="toAdPage">
        <div class="link-name">
          <van-image
              src="my/school.png"
              fit="contain"
          />
          商学院
        </div>
        <van-icon name="arrow"/>
      </div>
    </div>


    <div class="grid-container">
      <p>我的推广</p>
      <van-grid
          :column-num="Math.max(4, extensionGridListByPermission.length)"
          :border="false"
      >
        <van-grid-item
            v-for="{name, id, src, path} in extensionGridListByPermission"
            :text="name"
            :key="id"
            @click="handleExtensionClick(path)"
        >
          <template #icon>
            <van-image
                :src="src"
                fill="contain"
            />
          </template>
        </van-grid-item>
      </van-grid>
    </div>

    <div class="grid-container order-container">
      <p>我的订单<span @click="() => handleOrderClick('/orderList')">全部订单 <van-icon name="arrow"/></span></p>
      <van-grid
          :column-num="4"
          :border="false"
      >
        <van-grid-item
            v-for="order in orderGridList"
            :text="order.name"
            :key="order.id"
            @click="handleOrderClick(order.path)"
        >
          <template #icon>
            <van-image
                :src="order.src"
                fill="contain"
            />
          </template>
        </van-grid-item>

        <van-grid-item
            text="售后服务"
            @click="afterSaleList"
        >
          <template #icon>
            <van-image
                src="my/afterService.png"
                fill="contain"
            />
          </template>
        </van-grid-item>
      </van-grid>
    </div>

    <van-cell-group class="grid-container">
       <van-cell
          title="实名信息" icon="friends-o" is-link
          @click="$router.push('/userAuthentication')"
      />
      <van-cell title="收货地址" icon="location-o" is-link @click="address"/>
      <!-- <van-cell title="个人信息" icon="user-o" is-link @click="$router.push('/userInfoView')"/> -->
      <van-cell v-if="warehouseGoodsExist" title="待提货" icon="home-o" is-link @click="$router.push('/warehouse')"/>
      <van-cell
          v-if="isShowStoreOption"
          title="店铺信息" icon="shop-o" is-link @click="$router.push('/storeInfo')"/>
      <van-cell title="积分流水" icon="points" is-link @click="$router.push('/integralFlow')"/>
      <van-cell
        title="银行卡管理"
        icon="pending-payment"
        is-link
        @click="addBank"
      />
      <van-cell
        title="快捷支付"
        icon="idcard"
        is-link
        @click="quickPay"
      />
      <!--      <van-cell title="充值订单" icon="cash-back-record" is-link @click="$router.push('/depositOrderList')"/>-->


      <!--      <van-cell title="积分订单" icon="orders-o" is-link @click="integralOrderList"/>-->
      <van-cell v-if="isShowShareLink" title="分享二维码" icon="qr" is-link @click="$router.push('/shareLink')"/>
      <van-cell title="我的协议" icon="records" is-link @click="building"/>
      <!--      <van-cell v-if="isShowOldUserLogin" title="老用户登录" icon="user-o" is-link-->
      <!--                @click="$router.push('/userNamePswLogin')"/>-->
      <van-cell title="关于店铺" icon="smile-o" is-link @click="toAbout"/>
    </van-cell-group>
  </page-container>
</template>

<script>

import { mapState } from 'vuex';
import { getUserAccount, getUserInfo } from '@/api/user';
import { DAI_FU_KUAN, DAI_SHOU_HUO, YI_GUAN_BI } from '@/const/orderStatus';
import { Toast } from 'vant';
import { VUE_APP_ALWAYS_SHOW_OLD_USER_LOGIN, VUE_APP_SCHOOL_URL } from '@/config';
import { getToSchoolUrl } from '@/api/system';
import {getWarehouseGoodsList} from "@/api/warehouse";


export default {
  name: 'my',
  data() {
    return {
      scrollTop: 0,
      userInfo: {},
      accountInfo: {},
      warehouseGoodsList: [],
      orderGridList: [
        {
          id: '1',
          src: 'my/pendingPayment.png',
          name: '待付款',
          path: `/orderList?status=${DAI_FU_KUAN}`,
        },
        {
          id: '3',
          src: 'my/toBeShipped.png',
          name: '待收货',
          path: `/orderList?status=${DAI_SHOU_HUO}`,
        },
        {
          id: '5',
          src: 'my/toBeHarvested.png',
          name: '已关闭',
          path: `/orderList?status=${YI_GUAN_BI}`,
        },
        // {
        //   id: '6',
        //   src: 'my/afterService.png',
        //   name: '售后服务',
        //   path: '/afterSaleList',
        // }
      ],

    };
  },
  computed: {
    ...mapState({
      storeRefreshing: state => state.homeMy.refreshing,
    }),
    level() {
      return this.userInfo.level || '';
    },
    warehouseGoodsExist() {
      return this.warehouseGoodsList.length > 0;
    },
    extensionGridListByPermission() {
      let list = [
        // {
        //   id: 1,
        //   src: 'my/redEnvelopes.png',
        //   name: '广告红包',
        //   path: '/redPacket'
        // },
        {
          id: 2,
          src: 'my/commission.png',
          name: '佣金',
          // path: '/billingAndContribution',
          path: '/billingPage',
        },
        // {
        //   id: 3,
        //   src: 'my/extension.png',
        //   name: '推广',
        //   path: '/underUser',
        // },
        {
          id: 4,
          src: 'my/record.png',
          name: '提现记录',
          path: '/withdrawRecords',
        },
        {
          id: 5,
          src: 'my/withdrawal.png',
          name: '提现',
          path: '/withdraw',
        },
      ];
      let { status } = this.userInfo;
      if ((this.level >= 2 && status !== '01') || this.level === 3) {
        list = [
          ...list,
          {
            id: 3,
            src: 'my/extension.png',
            name: '推广',
            path: '/promoteUsers',
          },
        ];
      }
      return list.sort((a, b) => a.id - b.id);
    },
    userLevelNameArr() {
      let { level } = this;
      let nameArr = [];
      if (!level)
        return nameArr;

      let { status } = this.userInfo;
      if (level >= 2)
        nameArr = [...nameArr, '会员'];
      if (level === 2 && status !== '01')
        nameArr = [...nameArr, '居间'];
      if (level >= 3)
        nameArr = [...nameArr, '推广员'];

      return nameArr;
    },
    isShowShareLink() {
      let { level } = this;
      let { status } = this.userInfo;
      return level >= 2 && status !== '01';
    },
    // userInfo.status === '01' 显示老用户登录
    isShowOldUserLogin() {
      // 是否强制显示老用户登录 "0": 不强制显示 "1": 强制显示
      if (VUE_APP_ALWAYS_SHOW_OLD_USER_LOGIN === '1')
        return true;

      // let { level } = this;
      // let { status } = this.userInfo;
      // if (!level)
      //   return false;
      // let isJuJian = level === 2 && status !== '01';
      // let isTuiGuangYuan = level === 3;
      // return !isJuJian && !isTuiGuangYuan;

      let { status } = this.userInfo;
      return status === '01';
    },
    isShowStoreOption() {
      let { userInfo } = this;
      return userInfo && 'agencyStatus' in userInfo && (userInfo.agencyStatus === '02' || userInfo.agencyStatus === '05');
    },
  },
  created() {
    this.$watch('storeRefreshing', () => {
      if (!this.storeRefreshing)
        return;
      this.$store.commit('homeMy/setRefreshing', false);
      this.init();
    }, { immediate: false });
    getWarehouseGoodsList().then(data => {
      this.warehouseGoodsList = data || [];
    });
  },
  activated() {
    this.init();
    this.$refs.pageContainer.scrollToTop(this.scrollTop);
  },

  methods: {
    init() {
      getUserInfo().then(res => {
        this.userInfo = res || {};
      });
      getUserAccount().then(res => {
        this.accountInfo = res || {};
      });
    },

    // 个人资料
    editUserInfo() {
      this.$router.push("/user");
    },
    handleExtensionClick(path) {

      if(path==='/withdraw' && !this.userInfo.bankAccountNo){
        return Toast('未绑定银行卡');

      }
      path && this.$router.push(path);
      !path && Toast('功能开发中...');
    },
    handleOrderClick(path) {
      this.$store.commit('orderList/setRefreshing', true);
      this.$router.push(path);
    },
    addBank(){
      if(this.userInfo.isAuthentication===0){
        Toast('未实名认证')
      }else{
        this.$router.push('/bankList')
      }
      
    },
    quickPay(){
      if(this.userInfo.isAuthentication===0){
        Toast('未实名认证')
      }else{
        this.$router.push('/quickPayMent')
      }
    
    },

    // 商学院
    toAdPage() {
      getToSchoolUrl().then(res => {


        // let url = `http://192.168.31.181:8081?${res.url.split('?')[1]}`;
        // console.log(url);
        // // window.open(url);
        // return;


        if (res.url)
          window.location.href = res.url + '&type=mobile';
        // window.open(res.url + '&type=mobile');
        else
          Toast('地址错误');
      });
      // window.open(VUE_APP_SCHOOL_URL);
    },
    // 订单跳转控制
    goOrderListPage(isPaySuccess = false) {
      this.$store.commit('orderList/setRefreshing', true);
      this.$router.replace({
        path: '/orderList',
        query: {
          status: isPaySuccess ? '' : DAI_FU_KUAN,
        }
      });
    },
    address() {
      this.$store.commit('receiverAddress/setRefreshing', true);
      this.$router.push({
        path: '/addressList',
      });
    },
    afterSaleList() {
      this.$store.commit('afterSaleList/setRefreshing', true);
      this.$router.push({
        path: '/afterSaleList',
      });
    },
    // 积分商城
    integralGoods() {
      this.$store.commit('integralGoods/setRefreshing', true);
      this.$router.push('/integralGoods');
    },
    // 积分订单
    integralOrderList() {
      this.$store.commit('integralOrderList/setRefreshing', true);
      this.$router.push('/integralOrderList');
    },
    toAbout() {
      this.$copyText(sessionStorage.getItem('shopShareLink'));
      this.$router.push('/about');
    },
    building() {
      this.$router.push({ path: '/agreementList', });

      // Toast('功能开发中');
    },
  },
};
</script>

<style lang="less" scoped>
.my-container {
  .user-info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-sizing: border-box;
    padding: 0 20px;
    width: 100%;
    height: 180px;

    background: url("../../img/my/topBaseMap.png") center no-repeat;
    background-size: 100%;
   .user-info-arrow{
         width: 30px;
    height: 30px;
     position: absolute;
     top: 32px;
     right: 10px;
   }
    .user-info {
      display: flex;

      .user-avatar {
        flex-shrink: 0;
        width: 80px;
        height: 80px;
      }

      .user-money {
        flex: 1;
        box-sizing: border-box;
        padding: 0 0 0 20px;

        & > div {
          margin-bottom: 5px;
          font-size: 15px;
          font-weight: bolder;
        }

        p {
          margin: 0;
          line-height: 1.2;

          span {
            display: inline-block;
            font-size: 12px;

            & + span {
              margin-left: 20px;
            }
          }
        }

        ul {
          margin-top: 5px;
          font-size: 0;

          li {
            display: inline-block;
            width: 60px;
            height: 17px;
            background: linear-gradient(to right, #dcad6a, #b28c57);
            color: #f6efe5;
            text-align: center;
            line-height: 17px;
            border-radius: 12px;
            font-size: 12px;

            & + li {
              margin-left: 20px;
            }
          }
        }
      }


    }

    .link-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px 0 0;
      height: 30px;
      background-color: #FFFFFF;
      border-radius: 5px;
      border: 1px solid #eee;

      .link-name {
        display: flex;
        align-items: center;
        padding: 0 0 0 6px;
        height: 100%;
        font-size: 14px;
        color: #666;

        .van-image {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .grid-container {
    margin: 12px 6px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;

    & > p {
      margin: 0;
      padding: 2px 13px 0 13px;
      height: 40px;
      line-height: 40px;
      font-size: 15px;
      color: #333;
    }

    /deep/ .van-grid-item__content {
      padding: 4px 0 6px 0;
    }

    /deep/ .van-grid-item__text {
      line-height: 2;
      font-size: 12px;
    }

    .van-image {
      width: 24px;
      height: 24px;
    }

    &.order-container {

      & > p {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2px 0 0 13px;

        & > span {
          padding: 0 13px 0 0;
          font-size: 14px;
          color: #888;
        }
      }
    }
  }


  p {
    margin: 20px;
  }

}
</style>
